import {NgModule} from '@angular/core';
import {ApolloModule, APOLLO_OPTIONS} from 'apollo-angular';
import {HttpLinkModule, HttpLink} from 'apollo-angular-link-http';
import {InMemoryCache} from 'apollo-cache-inmemory';

//const uri = 'https://itaa.fr/trav/public/graphql/'; // <-- add the URL of the GraphQL server here
//const uri = 'https://server.capitaineb.com/graphql/'; // <-- add the URL of the GraphQL server here
//const uri = 'http://localhost:8000/graphql/'; // <-- add the URL of the GraphQL server here
//const uri = 'http://trav-env.uckxtctcsm.us-east-2.elasticbeanstalk.com/graphql/'; // <-- add the URL of the GraphQL server here
export function createApollo(httpLink: HttpLink) {
  let uri = 'https://server.capitaineb.com/graphql/';
  if(window.location.toString().toLowerCase().includes("localhost")) {
    uri = 'http://localhost:8000/graphql/';
  }
  return {
    link: httpLink.create({uri}),
    cache: new InMemoryCache(),
    shouldBatch: true,
  };
}

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
