import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { parse } from 'graphql';
import { CookieService, cookieServiceFactory } from 'angular2-cookie/core';
import { createUploadLink } from 'apollo-upload-client';
import { FetchResult } from 'apollo-link';
import { ApolloQueryResult } from 'apollo-client';
import { Observable } from 'rxjs';

import { DataProxy } from 'apollo-cache';
import { currencies } from './+societe/currencies';
interface base {
  idcompany: number;
  pincompany: string;
  created_at: string;
  created_by: number;
  created_host: string;
  created_app: string;
  created_browser: string;
  updated_at: string;
  updated_by: number;
  updated_host: string;
  updated_app: string;
  updated_browser: string;
  deleted_at: string;
  deleted_by: number;
  deleted_host: string;
  deleted_app: string;
  deleted_browser: string;
}
export interface Journalhd extends base {
  id: number;
  name: string;
  jhd_kind: number;
  jhd_date: string;
  jhd_wareh: number;
  jhd_posted: number;
  jhd_canceled: number;
  jhd_user: string;
  jhd_party: number;
  sellagent: number;
  statistics: number;
  tags: string;
  vattype: number;
  srcdoc: number;
  euro: number;

}
export interface Journalhdview extends base {
  id: number;
  name: string;
  jhd_kind: number;
  jhd_date: string;
  jhd_wareh: number;
  jhd_posted: number;
  jhd_canceled: number;
  jhd_user: string;
  jhd_party: number;
  sellagent: number;
  statistics: number;
  tags: string;
  party_name: string;
  buy: number;
  ht: number;
  vat: number;
  ttc: number;
  offert: number;
  total: number;
  vattype: number;
  srcdoc: number;
  euro: number;

}
export interface Warehouse {
  id: number;
  war_name: string;
  war_adress: string;
}
export interface Accounttype {
  id: number;
  name: string;
}
export interface Accountcategory {
  id: number;
  name: string;
  parent: number;
}
export interface Company {
  id: number;
  name: string;
  adress: string;
  ville: string;
  codepostal: string;
  country: number;
  image: string;
  ibanname: string;
  iban: string;
  ibanbank: string;
  bic: string;
  siret: string;
  tvan: string;
  rcs: string;
  tel: string;
  mail: string;
  pincompany: string;
  capital: string;
  article: string;
  fax: string;
  ibanagence: string;
  sirett: string;
  tvant: string;
  rcst: string;
  bict: string;
  tvadefault: number;
  tvadefaults: string;
  negativeqt: number;
  negativeqtpass: string;
  currency: string;
  currencycent: string;
  currencytext: string;
  currencysymbol: string;
  root: number;
  multistock: number;
  timbre: number;
  cartonon: number;
  offert: number;
  slogan: string;
  agreement: string;

}

export interface Expense extends base {
  id: number;
  typpe: number;
  account: number;
  jhd: number;
  amount: number;
  note: string;
  dateof: string;
  title: string;
}

export interface Payment extends base {
  id: number;
  typpe: number;
  account: number;
  party: number;
  jhd: number;
  amount: number;
  description: string;
  note: string;
  dateof: string;
  name: string;
  active_at: string;


}
export interface Transaction extends base {
  id: number;
  account1: number;
  account2: number;
  amount: number;
  note: string;
  dateof: string;
  title: string;
  state: number;
  euro: number;
  jhd: number;
  vattype: number;
  vatperc: number;
  vat: number;
  ht: number;




}
export interface Account {
  id: number;
  typpe: number;
  name: string;
  note: string;
  image: string;
}

export interface Party {
  id: number;
  party_name: string;
  party_type: number;
  party_adress: string;
  party_code: string;
  party_city: string;
  party_title: string;
  party_firstname: string;
  party_secondname: string;
  party_job: string;
  party_tel: string;
  mobile1: string;
  mobile2: string;
  v1: number;
  v2: number;
  w1: number;
  w2: number;
  interest: string;
  etat: number;
  goal: number;
  trash: number;
  agent: number;
  nis: string;
  party_email: string;
  image: string;
  rcs: string;
  na: string;
  mfn: string;
  centre: string;
  stade: number;
  age: number;
  adherent: number;
  surface: string;
  refrent: string;
  agr: string;
  datefcontact: string;
  daterelance: string;
  dateprevu: string;
  priority: number;
}
export interface Journalkind {
  id: number;
  jki_name: string;
  jki_module: string;
  jki_qty: number;
  jki_val: number;
}
export interface Journalline {
  id: number;
  jli_number: number;
  jli_itemno: number;
  jli_qty: number;
  qtf: number;
  carton: number;
  warehouse: number;
  jli_packsize: number;
  jli_uprice: number;
  jli_vatper: number;
  jli_vatval: number;
  jli_discpec: number;
  jli_discval: number;
  jli_lineval: number;
  totaloffert: number;
  jhd_kind: number;
  norder: number;
  serial: string;
  showdescription: string;
  description: string;
}
export interface Agent {
  id: number;
  firstname: string;
  secondname: string;
  name: string;
  email: string;
  password: string;
  typeagent: number;
  pasword: string;
  image: string;
  targett: number;
  selectall: Boolean;
  attached: String;
  rights: String;
  idcompany: number;
  pincompany: string;
}
export interface Product {
  id: number;
  name: string;
  namefr: string;
  ref: string;
  state: string;
  sellprice: number;
  sell_pricer: number;
  bsellprice: number;
  qt1: number;
  price1: number;
  qt2: number;
  price2: number;
  qt3: number;
  price3: number;
  qt4: number;
  price4: number;
  bqt1: number;
  bprice1: number;
  bqt2: number;
  bprice2: number;
  bqt3: number;
  bprice3: number;
  bqt4: number;
  bprice4: number;
  pack_size: number;
  buyprice: number;
  image: string;
  description: string;
  sav: number;
  category: number;
  typpe: number;
  promo: number;
  info: string;
}
export interface Productview {
  id: number;
  name: string;
  namefr: string;
  ref: string;
  state: string;
  sellprice: number;
  sell_pricer: number;
  bsellprice: number;
  qt1: number;
  price1: number;
  qt2: number;
  price2: number;
  qt3: number;
  price3: number;
  qt4: number;
  price4: number;
  bqt1: number;
  bprice1: number;
  bqt2: number;
  bprice2: number;
  bqt3: number;
  bprice3: number;
  bqt4: number;
  bprice4: number;
  pack_size: number;
  buyprice: number;
  image: string;
  description: string;
  sav: number;
  category: number;
  typpe: number;
  promo: number;
  wareh: number;
  war_name: string;
  stockqt: number;
  stockqtoff: number;
  stockqtf: number;
  stockval: number;
  stockoffval: number;
  stockfval: number;
}

export interface Envelope {
  id: number;
  name: string;
  remise1: number;
  remise2: number;
}
export interface Activite {
  id: number;
  name: string;
}
export interface Fin {
  id: number;
  name: string;
  kind: number,
  module: number,
  typpe: number;
  parent: number;
  image: string;
  note: string;
  nvalue: number;
  fvalue: number;
  svalue: string;

}
export interface Source {
  id: number;
  name: string;
}
export interface Etape {
  id: number;
  name: string;
}
export interface Category {
  id: number;
  name: string;
  image: string;
}

export interface Task {
  id: number;
  /*Facture-Bon*/
  doccontext: number,
  /*Factureid-Bonid*/
  docid: number,
  title: string,
  note: string,
  file: string,
  datestart: string,
  dateend: string,
  reserved: number,
  created_by: number,
  created_byname: string,
  created_at: string,
  terminated_at: string,
  terminated_by: string,
  terminated_byname: string,
  typpe: number,
  source: string,
  destination: string,
  idcontact: number,
  amount: number,
  terminated_amount: number,
  idcompany: number,
  pincompany: string,
}

export interface Usercomp {
  id: number;
  userid: number;
  companyid: number;
  typpe: number;
  email: string;

}

interface WarehouseList {
  products: Warehouse[];
}
interface AccountcategoryList {
  products: Accountcategory[];
}
interface TaskList {
  tasks: Task[];
}
interface AccounttypeList {
  products: Accounttype[];
}
interface PartyList {
  products: Party[];
}
interface PaymentList {
  payments: Payment[];
}
interface ExpenseList {
  expenses: Expense[];
}
interface TransactionList {
  transactions: Transaction[];
}

interface AccountList {
  accounts: Account[];
}

interface JournalhdList {
  products: Journalhd[];
}
interface JournalhdviewList {
  products: Journalhdview[];
}
interface JournalkindList {
  products: Journalkind[];
}
interface JournallineList {
  products: Journalline[];
}
interface ActiviteList {
  products: Activite[];
}
interface FinList {
  products: Fin[];
}
interface EtapeList {
  products: Etape[];
}
interface CategoryList {
  products: Category[];
}
interface UsercompList {
  usercomps: Usercomp[];
}
interface CompanyList {
  companys: Company[];
}

interface SourceList {
  products: Source[];
}
interface ProductList {
  products: Product[];
}
interface ProductviewList {
  productviews: Productview[];
}
interface AgentList {
  products: Agent[];
}
interface EnvelopeList {
  envelopes: Envelope[];
}
interface UploadPhoto {
  image: String;
}

interface Response {
  agentlogin: Agent;
  agentinfo: Agent;



  IntroduceJournalhd: Journalhd;
  IntroduceJournalline: Journalline;
  IntroduceJournalkind: Journalkind;
  IntroduceParty: Party;
  IntroducePayment: Payment;
  IntroduceTransaction: Transaction;
  IntroduceExpense: Expense;
  IntroduceAccount: Account;
  IntroduceWarehouse: Warehouse;
  IntroduceAccounttype: Accounttype;
  IntroduceAccountcategory: Accountcategory;

  IntroduceProduct: Product;
  IntroduceEtape: Etape;
  IntroduceCategory: Category;
  IntroduceTask: Task;
  IntroduceUsercomp: Usercomp;
  IntroduceCompany: Company;
  IntroduceFin: Fin;
  IntroduceSource: Source;
  IntroduceActivite: Activite;
  IntroduceAgent: Agent;

  UpdateJournalhd: Journalhd;
  UpdateJournalline: Journalline;
  UpdateJournalkind: Journalkind;
  UpdateParty: Party;
  UpdatePayment: Payment;
  UpdateTransaction: Transaction;
  UpdateExpense: Expense;
  UpdateAccount: Account;
  UpdateWarehouse: Warehouse;
  UpdateAccounttype: Accounttype;
  UpdateAccountcategory: Accountcategory;

  UpdateAgent: Agent;
  UpdateProduct: Product;
  IntroduceEnvelope: Envelope;
  UpdateEnvelope: Envelope;
  UpdateEtape: Etape;
  UpdateCategory: Category;
  UpdateTask: Task;
  UpdateUsercomp: Usercomp;
  UpdateCompany: Company;
  UpdateFin: Fin;
  UpdateSource: Source;
  UpdateActivite: Activite;

  journalhd_list: JournalhdList;
  journalhdview_list: JournalhdviewList;
  journalline_list: JournallineList;
  journalkind_list: JournalkindList;
  party_list: PartyList;
  accounttype_list: AccounttypeList;
  accountcategory_list: AccountcategoryList;
  warehouse_list: WarehouseList;
  payment_list: PaymentList;
  transaction_list: TransactionList;
  expense_list: ExpenseList;
  account_list: AccountList;


  agent_list: AgentList;
  product_list: ProductList;
  productview_list: ProductviewList;
  envelope_list: EnvelopeList;
  etape_list: EtapeList;
  category_list: CategoryList;
  task_list: TaskList;
  usercomp_list: UsercompList;
  company_list: CompanyList;
  fin_list: FinList;
  activite_list: ActiviteList;
  source_list: SourceList;
  dataproducts: ProductList;
  singleUpload: UploadPhoto;

}
export interface Action {
  do(error: number, result?: any);
}
@Injectable({
  providedIn: 'root'
})

export class LoginService implements CanActivate {
  TASK_TYPES = {
    NOTE: 1,
    TASK: 2,
    EMAIL: 3
  }




  getCurrency() {
    if (!this.company) return "EUR";//currencies.map(c=>''+c.id).indexOf('978')[0];
    return this.company.currency;

  }
  isAdmin() {
    if (this.user && (this.user.idcompany == null || this.user.idcompany == 0))
      return true;
    else return false;
  }

  stringred(x) {
    if (!x) { return 'null'; } else { return '"""' + x + '"""'; }

  }

  idcompany = 0;
  pincompany = '';
  emptybase: base = {
    'idcompany': null,
    'pincompany': null,
    'created_at': null,
    'created_by': null,
    'created_host': null,
    'created_app': null,
    'created_browser': null,
    'updated_at': null,
    'updated_by': null,
    'updated_host': null,
    'updated_app': null,
    'updated_browser': null,
    'deleted_at': null,
    'deleted_by': null,
    'deleted_host': null,
    'deleted_app': null,
    'deleted_browser': null,
  };
  getEmptyBase() {
    return this.emptybase;
  }
  public user: Agent;
  public company: Company;
  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    /** utilisateur */
    if (!this.user) return false;
    var allrights = this.getAllRights();
    var rights = this.user.rights.split('$');
    let auth = false;
    if (rights) {

      allrights.forEach(r => {

        if (route.url.toString().split(",")[0] == r.route)
          auth = true;
      })
    }
    //if(auth)
    return true;
    this.router.navigate(['dropdown']);
    return false;

    // return true;
  }
  public getUser(): Agent {
    this.user = <Agent>this._cookieService.getObject('user');
    return this.user;
  }
  public getCompany(): Company {
    this.company = <Company>this._cookieService.getObject('company');
    return this.company;
  }
  companys: Company[];
  allcompanys: Company[];
  usercomps: Usercomp[];
  constructor(private apollo: Apollo, private _cookieService: CookieService, public router: Router) {
    this.user = <Agent>this._cookieService.getObject('user');
    this.company = <Company>this._cookieService.getObject('company');
    this.companys = <Company[]>this._cookieService.getObject('companys');
    if (this.company) { this.idcompany = this.company.id; }

    this.resolveCompanies(this.user);

  }
  postloadaction = null;
  addLoadAction(actionpost) {


    if (this.companys && this.companys.length > 0 && actionpost) {
      actionpost.do();
    } else this.postloadaction = actionpost;
  }
  resolveCompanies(user) {
    let that = this;

    if (user) {

      this.getCompanysandUserComp({
        do(error: number, result: any) {


          that.companys = result.data.company_list.companys;
          that.allcompanys = result.data.company_list.companys;
          that.usercomps = result.data.usercomp_list.usercomps;

          that.usercomps = that.usercomps.filter(uc => uc.userid == user.id);

          that.companys = that.companys.filter(
            c => c.id == user.idcompany || that.usercomps.filter(uc => uc.companyid == c.id).length > 0
          );

          if (that.companys.length > 0 && that.company) {

            let savedCompanyExist = that.companys.filter(
              c => c.id == that.company.id
            ).length > 0;

            if (!savedCompanyExist) {

              that.company = that.companys[0];
              that.idcompany = that.company.id;
              that._cookieService.putObject('company', that.company);
              that._cookieService.putObject('companys', that.companys);
            }

          } else if (that.companys.length > 0 && !that.company) {

            that.company = that.companys[0];
            that.idcompany = that.company.id;
            that._cookieService.putObject('company', that.company);
            that._cookieService.putObject('companys', that.companys);
          }
          else {

            that.company = null;
            that.idcompany = 0;
            that._cookieService.putObject('company', that.company);
            that._cookieService.putObject('companys', that.companys);
          }
          that.hideLinks();
          if (that.postloadaction) {
            that.postloadaction.do();

            that.postloadaction = null;
          }

        }
      });
    }
  }
  public login(email: string, password: string, action: Action) {
    let that = this;
    this.apollo
      .watchQuery<Response>({
        query: parse(`
      query {
        agentlogin(email: ${this.stringred(email)},password: ${this.stringred(password)}) {
          id
          firstname
          secondname
          password
          email
          typeagent
          targett
          selectall
          attached
          rights
          name
          image
          idcompany
          pincompany
        }
        company_list(limit:50000) {
          companys {
            id
            name
            adress
            ville
            codepostal
            country
            image
            ibanname
            iban
            ibanbank
            bic
            siret
            tvan
            rcs
            tel
            mail
            pincompany
            capital
            article
            fax
            ibanagence
            sirett
            tvant
            rcst
            bict
            tvadefault
            tvadefaults
            negativeqt
            negativeqtpass
            currency
            currencycent
            currencytext
            currencysymbol
            root
            timbre
            multistock
            timbre
            cartonon
            offert
            slogan
            agreement             }
        }
        usercomp_list(limit:50000) {
          usercomps {
            id
            userid
            companyid
            typpe
          }
        }
      }
      `)
      })
      .valueChanges.subscribe(result => {

        if (result.data.agentlogin != null) {
          that.user = result.data.agentlogin;
          that._cookieService.putObject('user', this.user);

          that.resolveCompanies(that.user);
          action.do(0);



        } else { action.do(1); }

      });

  }
  public setCompany(companys, idcompany) {
    let that = this;

    let ncompanys = companys.filter(
      c => c.id == idcompany
    );
    if (ncompanys.length > 0) {
      that.idcompany = ncompanys[0].id;
      this._cookieService.putObject('company', ncompanys[0]);
    }
  }
  public updateCompanyCache(company) {
    let that = this;

    that.idcompany = company.id;
    that.company = company;
    this._cookieService.putObject('company', company);
  }
  public saveuser(agent: Agent) {
    this._cookieService.putObject('user', agent);
  }
  public getUserInfo(action: Action) {

    this.apollo
      .watchQuery<Response>({
        query: parse(`
      query {
        agentinfo(id: ${this.user.id}) {
          id
          firstname
          secondname
          password
          email
          typeagent
          targett
          selectall
          attached
          rights
          name
          idcompany
          pincompany
        }
      }
      `), fetchPolicy: 'no-cache'
      })
      .valueChanges.subscribe(result => {

        if (result.data.agentinfo != null) {
          this.user = result.data.agentinfo;
          action.do(0, result.data.agentinfo);

        } else { action.do(1); }

      });

  }

  public getProducts(action: Action) {
    this.apollo
      .watchQuery<Response>({
        query: gql`
      {product_list(limit:50000,
        idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        products {
          id
          name
          namefr
          ref
          state
          sellprice
          sell_pricer
          bsellprice
          qt1
          price1
          qt2
          price2
          qt3
          price3
          qt4
          price4
          bqt1
          bprice1
          bqt2
          bprice2
          bqt3
          bprice3
          bqt4
          bprice4
          pack_size
          buyprice
          image
          description
          sav
          category
          typpe
          promo
          info
        }
      }}
      `, fetchPolicy: 'no-cache'
      })
      .valueChanges.subscribe(result => {

        action.do(0, result);

      });


  }
  public getProductviews(action: Action) {
    this.apollo
      .watchQuery<Response>({
        query: gql`
      {productview_list(limit:50000,
        idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        productviews {
          id
          name
          namefr
          ref
          state
          sellprice
          sell_pricer
          bsellprice
          qt1
          price1
          qt2
          price2
          qt3
          price3
          qt4
          price4
          bqt1
          bprice1
          bqt2
          bprice2
          bqt3
          bprice3
          bqt4
          bprice4
          pack_size
          buyprice
          image
          description
          sav
          category
          typpe
          promo
          info
          wareh
          war_name
          stockqt
          stockqtoff
          stockqtf
          stockval
          stockoffval
          stockfval
        }
      }
      product_list(limit:50000,
        idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        products {
          id
          name
          namefr
          ref
          state
          sellprice
          sell_pricer
          bsellprice
          qt1
          price1
          qt2
          price2
          qt3
          price3
          qt4
          price4
          bqt1
          bprice1
          bqt2
          bprice2
          bqt3
          bprice3
          bqt4
          bprice4
          pack_size
          buyprice
          image
          description
          sav
          category
          typpe
          promo
          info
        }
      }
    }
      `, fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(result => {

        action.do(0, result);

      });


  }

  public getJournallines(id: number, action: Action) {
    this.apollo
      .watchQuery<Response>({
        query: parse(`
      {journalline_list(id:${id},limit:50000,
        idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        journallines {
          id
          jli_number
          jli_itemno
          jli_qty
          qtf
          carton
          warehouse
          jli_packsize
          jli_uprice
          jli_vatper
          jli_vatval
          jli_discpec
          jli_discval
          jli_lineval
          totaloffert
          jhd_kind
          norder
          serial
          showdescription
          description

        }
      }}
      `), fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(result => {

        action.do(0, result);

      });


  }
  public getJournallinesForProduct(productid: number, action: Action) {
    this.apollo
      .watchQuery<Response>({
        query: parse(`
      {journalline_list(productid:${productid},limit:50000,
        idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        journallines {
          id
          jli_number
          jli_itemno
          jli_qty
          qtf
          carton
          warehouse
          jli_packsize
          jli_uprice
          jli_vatper
          jli_vatval
          jli_discpec
          jli_discval
          jli_lineval
          totaloffert
          jhd_kind
          norder
          serial
          showdescription
          description

        }
      }}
      `), fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(result => {

        action.do(0, result);

      });


  }
  public getWarehouses(action: Action) {
    this.apollo
      .watchQuery<Response>({
        query: gql`
      {warehouse_list(limit:50000,
        idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        warehouses {
          id
          war_name
          war_adress
        }
      }}
      `, fetchPolicy: 'no-cache'
      })
      .valueChanges.subscribe(result => {

        action.do(0, result);

      });


  }

  basegql = `
    idcompany
    pincompany
    created_at
    created_by
    created_host
    created_app
    created_browser
    updated_at
    updated_by
    updated_host
    updated_app
    updated_browser
    deleted_at
    deleted_by
    deleted_host
    deleted_app
    deleted_browser

  `;
  public getPayments(id: number, action: Action) {
    this.apollo
      .watchQuery<Response>({
        query: parse(`
      {payment_list(id:${id},limit:50000,
        idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        payments {
          id
          typpe
          party
          jhd
          amount
          dateof
          active_at
          account
          note
          ${this.basegql}
        }
      }}
      `), fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(result => {

        action.do(0, result);

      });


  }
  public getPaymentsAndTransactions(id: number, action: Action) {
    this.apollo
      .watchQuery<Response>({
        query: parse(`
      {payment_list(id:${id},limit:50000,
        idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        payments {
          id
          typpe
          party
          jhd
          amount
          dateof
          active_at
          account
          note
          ${this.basegql}
        }
      }
      transaction_list(id:null,limit:50000,
        idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        transactions {
          id
          account1
          account2
          amount
          note
          dateof
          title
          state	
          euro
          jhd
          vattype  
          vatperc
          vat
          ht          
          ${this.basegql}
        }
      }
    }
      `), fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(result => {

        action.do(0, result);

      });


  }
  public getTransactions(id: number, action: Action) {
    this.apollo
      .watchQuery<Response>({
        query: parse(`
      {transaction_list(id:${id},limit:50000,
        idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        transactions {
          id
          account1
          account2
          amount
          note
          dateof
          title
          state	
          euro
          jhd
          vattype  
          vatperc
          vat
          ht          
          ${this.basegql}
        }
      }}
      `), fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(result => {

        action.do(0, result);

      });


  }
  public getAccounts(id: number, action: Action) {
    this.apollo
      .watchQuery<Response>({
        query: parse(`
      {account_list(id:${id},limit:50000,
        idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        accounts {
          id
          typpe
          name
          note
          image
          ${this.basegql}
        }
      }}
      `), fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(result => {

        action.do(0, result);

      });


  }
  public getExpenses(id: number, action: Action) {
    this.apollo
      .watchQuery<Response>({
        query: parse(`
      {expense_list(id:${id},limit:50000,
        idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        expenses {
          id
          typpe
          account
          jhd
          amount
          note
          dateof
          title
          description
          idcompany
          pincompany
          created_at
          created_by
          created_host
          created_app
          created_browser
          updated_at
          updated_by
          updated_host
          updated_app
          updated_browser
          deleted_at
          deleted_by
          deleted_host
          deleted_app
          deleted_browser
        }
      }}
      `), fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(result => {

        action.do(0, result);

      });


  }
  public getPaymentsAndJournalhdviews(id: number, type_party: number, action: Action) {

    this.apollo
      .watchQuery<Response>({
        query: parse(`
      {payment_list(id:${id},limit:50000,
        idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        payments {
          id
          typpe
          party
          jhd
          amount
          dateof
          active_at
          account
          note
          ${this.basegql}
        }
      }
      party_list(limit:50000,type_party:${type_party},
        idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        partys {
          id
          party_name
          party_type
          party_adress
          party_code
          party_city
          party_title
          party_firstname
          party_secondname
          party_job
          party_tel
          party_email
          image
          rcs
          na
          mfn
          centre
          stade
          age
          adherent
          surface
          refrent
          agr
          datefcontact
          daterelance
          dateprevu
          priority
          mobile1
          mobile2
          nis
          v1
          v2
          w1
          w2
          interest
          etat
          goal
          trash
          agent


        }
      }
      journalhdview_list(id:${id},limit:50000,
        idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        journalhdviews {
          id
          name
          jhd_kind
          jhd_date
          jhd_wareh
          jhd_posted
          jhd_canceled
          jhd_user
          jhd_party
          sellagent
          statistics
          tags
          vattype
          srcdoc
          euro
          party_name
          buy
          ht
          vat
          ttc
          offert
          total
          ${this.basegql}
        }
      }
    }
      `), fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(result => {

        action.do(0, result);

      });


  }
  public getPartys(type_party: number, action: Action) {
    this.apollo
      .watchQuery<Response>({
        query: parse(`
      {party_list(limit:50000,type_party:${type_party},
        idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        partys {
          id
          party_name
          party_type
          party_adress
          party_code
          party_city
          party_title
          party_firstname
          party_secondname
          party_job
          party_tel
          party_email
          image
          rcs
          na
          mfn
          centre
          stade
          age
          adherent
          surface
          refrent
          agr
          datefcontact
          daterelance
          dateprevu
          priority
          mobile1
          mobile2
          nis
          v1
          v2
          w1
          w2
          interest
          etat
          goal
          trash
          agent


        }
      }}
      `), fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(result => {

        action.do(0, result);

      });


  }
  public getJournalhds(action: Action) {
    this.apollo
      .watchQuery<Response>({
        query: gql`
      {journalhd_list(limit:50000,
        idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        journalhds {
          id
          name
          jhd_kind
          jhd_date
          jhd_wareh
          jhd_posted
          jhd_canceled
          jhd_user
          jhd_party
          sellagent
          statistics
          tags
          vattype
          srcdoc
          euro
          ${this.basegql}
        }
      }}
      `, fetchPolicy: 'no-cache'
      })
      .valueChanges.subscribe(result => {

        action.do(0, result);

      });


  }

  public getJournalhdviews(action: Action) {
    this.apollo
      .watchQuery<Response>({
        query: gql`
      {journalhdview_list(limit:50000,
        idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        journalhdviews {
          id
          name
          jhd_kind
          jhd_date
          jhd_wareh
          jhd_posted
          jhd_canceled
          jhd_user
          jhd_party
          sellagent
          statistics
          tags
          vattype
          srcdoc
          euro
          party_name
          buy
          ht
          vat
          ttc
          offert
          total
          ${this.basegql}
        }
      }
    }
      `, fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(result => {

        action.do(0, result);

      });


  }
  public getAllBons(type_party: number, action: Action) {

    this.apollo
      .watchQuery<Response>({
        query: gql`
      {journalhdview_list(limit:50000,
        idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        journalhdviews {
          id
          name
          jhd_kind
          jhd_date
          jhd_wareh
          jhd_posted
          jhd_canceled
          jhd_user
          jhd_party
          sellagent
          statistics
          tags
          vattype
          srcdoc
          euro
          party_name
          buy
          ht
          vat
          ttc
          offert
          total
          ${this.basegql}
        }
      }
      warehouse_list(limit:50000,
        idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        warehouses {
          id
          war_name
          war_adress
        }
      }      
      payment_list(id:null,limit:50000,
        idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        payments {
          id
          typpe
          party
          jhd
          amount
          dateof
          active_at
          account
          note
          ${this.basegql}
        }
      }
      party_list(limit:50000,type_party:${type_party},
        idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        partys {
          id
          party_name
          party_type
          party_adress
          party_code
          party_city
          party_title
          party_firstname
          party_secondname
          party_job
          party_tel
          party_email
          image
          rcs
          na
          mfn
          centre
          stade
          age
          adherent
          surface
          refrent
          agr
          datefcontact
          daterelance
          dateprevu
          priority
          mobile1
          mobile2
          nis
          v1
          v2
          w1
          w2
          interest
          etat
          goal
          trash
          agent


        }
      }  
      productview_list(limit:50000,
        idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        productviews {
          id
          name
          namefr
          ref
          state
          sellprice
          sell_pricer
          bsellprice
          qt1
          price1
          qt2
          price2
          qt3
          price3
          qt4
          price4
          bqt1
          bprice1
          bqt2
          bprice2
          bqt3
          bprice3
          bqt4
          bprice4
          pack_size
          buyprice
          image
          description
          sav
          category
          typpe
          promo
          info
          wareh
          war_name
          stockqt
          stockqtoff
          stockqtf
          stockval
          stockoffval
          stockfval
        }
      }
      product_list(limit:50000,
        idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        products {
          id
          name
          namefr
          ref
          state
          sellprice
          sell_pricer
          bsellprice
          qt1
          price1
          qt2
          price2
          qt3
          price3
          qt4
          price4
          bqt1
          bprice1
          bqt2
          bprice2
          bqt3
          bprice3
          bqt4
          bprice4
          pack_size
          buyprice
          image
          description
          sav
          category
          typpe
          promo
          info
        }
      }
      agent_list(limit:50000,idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        agents {
          id
          firstname
          secondname
          typeagent
          email
          password
          image
          name
          targett
          selectall
          attached
          rights
          idcompany
          pincompany
        }
      }
      category_list(limit:50000,,
        idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        categorys {
          id
          name
          image
        }
      }   
      account_list(id:null,limit:50000,
        idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        accounts {
          id
          typpe
          name
          note
          image
          ${this.basegql}
        }
      } 
      fin_list(limit:50000,kind: null,module: 1
        ,idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        fins {
          id
          name
          kind
          module
          typpe
          parent
          image
          note
          nvalue
          fvalue
          svalue
        }
      }
    }
      `, fetchPolicy: 'no-cache',
      })
      .valueChanges.subscribe(result => {

        action.do(0, result);

      });


  }
  public getJournalkinds(action: Action) {
    this.apollo
      .watchQuery<Response>({
        query: gql`
      {journalkind_list(limit:50000) {
        journalkinds {
          id
          jki_name
          jki_module
          jki_qty
          jki_val
        }
      }}
      `, fetchPolicy: 'no-cache'
      })
      .valueChanges.subscribe(result => {

        action.do(0, result);

      });


  }

  public getProductsandAgents(action: Action) {
    this.apollo
      .watchQuery<Response>({
        query: gql`
      {product_list(limit:50000,
        idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        products {
          id
          name
          namefr
          ref
          state
          sellprice
          sell_pricer
          bsellprice
          qt1
          price1
          qt2
          price2
          qt3
          price3
          qt4
          price4
          bqt1
          bprice1
          bqt2
          bprice2
          bqt3
          bprice3
          bqt4
          bprice4
          pack_size
          buyprice
          image
          description
          sav
          category
          typpe
          promo
          info
        }
      }
      party_list(limit:50000,type_party:2,
        idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        partys {
          id
          party_name
          party_type
          party_adress
          party_code
          party_city
          party_title
          party_firstname
          party_secondname
          party_job
          party_tel
          party_email
          image
          rcs
          na
          mfn
          centre
          stade
          age
          adherent
          surface
          refrent
          agr
          datefcontact
          daterelance
          dateprevu
          priority
          mobile1
          mobile2
          nis
          v1
          v2
          w1
          w2
          interest
          etat
          goal
          trash
          agent


        }
      }
      agentinfo(id: ${this.user.id}) {
        id
        firstname
        secondname
        password
        email
        typeagent
        targett
        selectall
        attached
        rights
        name
        idcompany
        pincompany
      }
      agent_list(limit:50000,idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        agents {
          id
          firstname
          secondname
          typeagent
          email
          password
          image
          name
          targett
          selectall
          attached
          rights
          idcompany
          pincompany
        }
      }
      envelope_list(limit:50000) {
        envelopes {
          id
          name
          remise1
          remise2
        }
      }
    	activite_list(limit:50000) {
          activites {
            id
            name
          }
        }
    	etape_list(limit:50000) {
          etapes {
            id
            name
          }
        }
    	fin_list(limit:50000) {
          fins {
            id
            name
          }
        }
    	source_list(limit:50000) {
          sources {
            id
            name
          }
        }

    }
      `, fetchPolicy: 'no-cache'
      })
      .valueChanges.subscribe(result => {

        action.do(0, result);

      });


  }
  public deleteJournalhd(idn: number, action: Action) {
    this.apollo.mutate({
      mutation: gql`
      mutation( $id:Int!,$idcompany:Int,$pincompany:String) {
        DeleteJournalhd(id: $id,idcompany: $idcompany,pincompany: $pincompany)
        {
          error
          message
        }
      }
      `,
      variables: {
        id: idn,
        idcompany: this.idcompany,
        pincompany: this.pincompany

      }
    }).subscribe(({ data }) => {

      action.do(data['DeleteJournalhd'].error);
    }, (error) => {
      console.log('there was an error sending the query', error);

    });

  }

  public deleteWarehouse(idn: number, action: Action) {
    this.apollo.mutate({
      mutation: gql`
      mutation( $id:Int!,$idcompany:Int,$pincompany:String) {
        DeleteWarehouse(id: $id,idcompany: $idcompany,pincompany: $pincompany
          )
        {
          error
          message
        }
      }
      `,
      variables: {
        id: idn,
        idcompany: this.idcompany,
        pincompany: this.pincompany

      }
    }).subscribe(({ data }) => {

      action.do(data['DeleteWarehouse'].error);
    }, (error) => {
      console.log('there was an error sending the query', error);

    });

  }

  public deleteParty(idn: number, action: Action) {
    this.apollo.mutate({
      mutation: gql`
      mutation( $id:Int!,$idcompany:Int,$pincompany:String) {
        DeleteParty(id: $id,idcompany: $idcompany,pincompany: $pincompany
          )
        {
          error
          message
        }
      }
      `,
      variables: {
        id: idn,
        idcompany: this.idcompany,
        pincompany: this.pincompany

      }
    }).subscribe(({ data }) => {

      action.do(data['DeleteParty'].error);
    }, (error) => {
      console.log('there was an error sending the query', error);

    });

  }

  public deleteJournalkind(idn: number, action: Action) {
    this.apollo.mutate({
      mutation: gql`
      mutation( $id:Int!) {
        DeleteJournalkind(id: $id)
        {
          error
          message
        }
      }
      `,
      variables: {
        id: idn
      }
    }).subscribe(({ data }) => {

      action.do(data['DeleteProduct'].error);
    }, (error) => {
      console.log('there was an error sending the query', error);

    });

  }

  public deleteJournalline(idn: number, action: Action) {
    this.apollo.mutate({
      mutation: gql`
      mutation( $id:Int!,$idcompany:Int,$pincompany:String) {
        DeleteJournalline(id: $id,idcompany: $idcompany,pincompany: $pincompany
          )
        {
          error
          message
        }
      }
      `,
      variables: {
        id: idn,
        idcompany: this.idcompany,
        pincompany: this.pincompany

      }
    }).subscribe(({ data }) => {

      action.do(data['DeleteProduct'].error);
    }, (error) => {
      console.log('there was an error sending the query', error);

    });

  }

  public deleteProduct(idn: number, action: Action) {
    this.apollo.mutate({
      mutation: gql`
      mutation( $id:Int!,$idcompany:Int,$pincompany:String) {
        DeleteProduct(id: $id,idcompany: $idcompany,pincompany: $pincompany
          )
        {
          error
          message
        }
      }
      `,
      variables: {
        id: idn,
        idcompany: this.idcompany,
        pincompany: this.pincompany

      }
    }).subscribe(({ data }) => {

      action.do(data['DeleteProduct'].error);
    }, (error) => {
      console.log('there was an error sending the query', error);

    });

  }

  public deleteEnvelope(idn: number, action: Action) {
    this.apollo.mutate({
      mutation: gql`
      mutation( $id:Int!) {
        DeleteEnvelope(id: $id)
        {
          error
          message
        }
      }
      `,
      variables: {
        id: idn
      }
    }).subscribe(({ data }) => {

      action.do(data['DeleteEnvelope'].error);
    }, (error) => {
      console.log('there was an error sending the query', error);
    });

  }
  public introduceProduct(name: string, namefr: string, ref: string, state: number, sellprice: number,
    bsellprice: number,

    qt1: number, price1: number,
    qt2: number, price2: number,
    qt3: number, price3: number,
    qt4: number, price4: number,

    bqt1: number, bprice1: number,
    bqt2: number, bprice2: number,
    bqt3: number, bprice3: number,
    bqt4: number, bprice4: number,

    sell_pricer: number, pack_size: number, buyprice: number, image: string, description: string, sav: number,
    category: number, typpe: number, promo: number, info: string, action: Action) {

    this.apollo
      .mutate<Response>({
        mutation: parse(`
      mutation {
        IntroduceProduct(name: ${this.stringred(name)},namefr: ${this.stringred(namefr)},ref: ${this.stringred(ref)},state: ${state},sellprice: ${sellprice},sell_pricer: ${sell_pricer},
        bsellprice: ${bsellprice},

        qt1: ${qt1},price1: ${price1}
        qt2: ${qt2},price2: ${price2}
        qt3: ${qt3},price3: ${price3}
        qt4: ${qt4},price4: ${price4}

        bqt1: ${bqt1},bprice1: ${bprice1}
        bqt2: ${bqt2},bprice2: ${bprice2}
        bqt3: ${bqt3},bprice3: ${bprice3}
        bqt4: ${bqt4},bprice4: ${bprice4}

        pack_size: ${pack_size},buyprice: ${buyprice},image: ${this.stringred(image)},description: ${this.stringred(description)},sav: ${sav}
        ,category: ${category},typpe: ${typpe},promo: ${promo},info: ${this.stringred(info)},
        idcompany:${this.idcompany},pincompany:"${this.pincompany}"
) {
          id
          name
          namefr
          ref
          state
          sellprice
          sell_pricer
          bsellprice
          qt1
          price1
          qt2
          price2
          qt3
          price3
          qt4
          price4
          bqt1
          bprice1
          bqt2
          bprice2
          bqt3
          bprice3
          bqt4
          bprice4
          pack_size
          buyprice
          image
          description
          sav
          category
          typpe
          promo
          info
        }
      }
      `),
      }).subscribe(({ data }) => {
        if (data.IntroduceProduct != null) {
          // this.user = data.IntroduceProduct;
          action.do(0, data.IntroduceProduct);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }

  public updateProduct(id: number, name: string, namefr: string, ref: string, state: number, sellprice: number, sell_pricer: number,
    bsellprice: number,

    qt1: number, price1: number,
    qt2: number, price2: number,
    qt3: number, price3: number,
    qt4: number, price4: number,

    bqt1: number, bprice1: number,
    bqt2: number, bprice2: number,
    bqt3: number, bprice3: number,
    bqt4: number, bprice4: number,

    pack_size: number, buyprice: number, image: string, description: string, sav: number
    , category: number, typpe: number, promo: number, info: string, action: Action) {
    this.apollo
      .mutate<Response>({
        mutation: parse(`
      mutation {
        UpdateProduct(id: ${id},name: ${this.stringred(name)},namefr: ${this.stringred(namefr)},ref: ${this.stringred(ref)},state: ${state},sellprice: ${sellprice},sell_pricer: ${sell_pricer},
        bsellprice: ${bsellprice},

        qt1: ${qt1},price1: ${price1}
        qt2: ${qt2},price2: ${price2}
        qt3: ${qt3},price3: ${price3}
        qt4: ${qt4},price4: ${price4}

        bqt1: ${bqt1},bprice1: ${bprice1}
        bqt2: ${bqt2},bprice2: ${bprice2}
        bqt3: ${bqt3},bprice3: ${bprice3}
        bqt4: ${bqt4},bprice4: ${bprice4}

        pack_size: ${pack_size},buyprice: ${buyprice},image: ${this.stringred(image)},description: ${this.stringred(description)},sav: ${sav}
        ,category: ${category},typpe: ${typpe},promo: ${promo},info: ${this.stringred(info)},
        idcompany:${this.idcompany},pincompany:"${this.pincompany}"
) {
          id
          name
          namefr
          ref
          state
          sellprice
          sell_pricer
          bsellprice
          qt1
          price1
          qt2
          price2
          qt3
          price3
          qt4
          price4
          bqt1
          bprice1
          bqt2
          bprice2
          bqt3
          bprice3
          bqt4
          bprice4
          pack_size
          buyprice
          image
          description
          sav
          category
          typpe
          promo
          info
        }
      }
      `),
      }).subscribe(({ data }) => {
        if (data.UpdateProduct != null) {
          // this.user = data.IntroduceProduct;
          action.do(0, data.UpdateProduct);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public updateProductpromo(id: number, promo: number, action: Action) {
    this.apollo
      .mutate<Response>({
        mutation: parse(`
      mutation {
        UpdateProduct(id: ${id},promo: ${promo},
          idcompany:${this.idcompany},pincompany:"${this.pincompany}"
  ) {
          id
          name
          namefr
          ref
          state
          sellprice
          sell_pricer
          bsellprice
          qt1
          price1
          qt2
          price2
          qt3
          price3
          qt4
          price4
          bqt1
          bprice1
          bqt2
          bprice2
          bqt3
          bprice3
          bqt4
          bprice4
          pack_size
          buyprice
          image
          description
          sav
          category
          typpe
          promo
        }
      }
      `),
      }).subscribe(({ data }) => {
        if (data.UpdateProduct != null) {
          // this.user = data.IntroduceProduct;
          action.do(0, data.UpdateProduct);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }

  public introduceJournalhd(name: string, jhd_kind: number, jhd_date: string, jhd_wareh: number, jhd_posted: number, jhd_canceled: number, jhd_user: string, jhd_party: number
    , sellagent: number,
    statistics: number,
    tags: string, vattype: number,
    srcdoc: number, euro: number
    , action: Action) {
    this.apollo
      .mutate<Response>({
        mutation: parse(`
      mutation {
        IntroduceJournalhd(name: ${this.stringred(name)},jhd_kind: ${jhd_kind},jhd_date: ${this.stringred(jhd_date)},jhd_wareh: ${jhd_wareh},jhd_posted: ${jhd_posted},jhd_canceled: ${jhd_canceled},jhd_user: ${this.stringred(jhd_user)},jhd_party: ${jhd_party}
        ,sellagent: ${sellagent}
        ,statistics: ${statistics}
        ,tags: ${this.stringred(tags)}
        ,created_by:${this.getUser().id}
        ,vattype: ${vattype},srcdoc: ${srcdoc},euro: ${euro}
        ,idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
          id
          name
          jhd_kind
          jhd_date
          jhd_wareh
          jhd_posted
          jhd_canceled
          jhd_user
          jhd_party
          sellagent
          statistics
          tags
          vattype
          srcdoc
          euro
          ${this.basegql}
        }
      }
      `),
      }).subscribe(({ data }) => {
        if (data.IntroduceJournalhd != null) {
          // this.user = data.IntroduceProduct;
          action.do(0, data.IntroduceJournalhd);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public updateJournalhd(id: number, name: string, jhd_kind: number, jhd_date: string, jhd_wareh: number, jhd_posted: number, jhd_canceled: number, jhd_user: string, jhd_party: number
    , sellagent: number,
    statistics: number,
    tags: string, vattype: number,
    srcdoc: number

    , euro: number, action: Action) {

    this.apollo
      .mutate<Response>({
        mutation: parse(`
      mutation {
        UpdateJournalhd(id: ${id},name: ${this.stringred(name)},jhd_kind: ${jhd_kind},jhd_date: ${this.stringred(jhd_date)}
        ,jhd_wareh: ${jhd_wareh},jhd_posted: ${jhd_posted},jhd_canceled: ${jhd_canceled},jhd_user: ${this.stringred(jhd_user)},jhd_party: ${jhd_party}
        ,sellagent: ${sellagent}
        ,statistics: ${statistics}
        ,tags: ${this.stringred(tags)}
        ,updated_by:${this.getUser().id}
        ,vattype: ${vattype},srcdoc: ${srcdoc},euro: ${euro}
        ,idcompany:${this.idcompany},pincompany:"${this.pincompany}"
        ) {
          id
          name
          jhd_kind
          jhd_date
          jhd_wareh
          jhd_posted
          jhd_canceled
          jhd_user
          jhd_party
          sellagent
          statistics
          tags
          vattype
          srcdoc
          euro
          ${this.basegql}
        }
      }
      `),
      }).subscribe(({ data }) => {
        if (data.UpdateJournalhd != null) {
          // this.user = data.IntroduceProduct;
          action.do(0, data.UpdateJournalhd);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public introducePayment(type: number, party: number, jhd: number,
    amount: number, dateof: string, account: number, description: string,
    note: string, active_at: string,
    action: Action) {
    this.apollo
      .mutate<Response>({
        mutation: parse(`
      mutation {
        IntroducePayment(
          typpe: ${type},party: ${party},jhd: ${jhd},
          amount: ${amount},dateof: ${this.stringred(dateof)},account:${account}
          ,description: ${this.stringred(description)},note: ${this.stringred(note)},active_at: ${this.stringred(active_at)}
          ,idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
          id
          typpe
          account
          party
          jhd
          amount
          description
          note
          dateof
          active_at
          ${this.basegql}
        }
      }
      `),
      }).subscribe(({ data }) => {
        if (data.IntroducePayment != null) {
          // this.user = data.IntroduceProduct;
          action.do(0, data.IntroducePayment);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public updatePayment(id: number, type: number, party: number, jhd: number,
    amount: number, dateof: string, account: number, description: string,
    note: string, active_at: string, action: Action) {
    if (!active_at) { active_at = 'now'; }
    this.apollo
      .mutate<Response>({
        mutation: parse(`
      mutation {
        UpdatePayment(
          id: ${id},typpe: ${type},party: ${party},jhd: ${jhd},
          amount: ${amount},dateof: ${this.stringred(dateof)},account:${account}
          ,description: ${this.stringred(description)},note: ${this.stringred(note)},active_at: ${this.stringred(active_at)}
          ,idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
            id
            typpe
            account
            party
            jhd
            amount
            description
            note
            dateof
            active_at
            ${this.basegql}
          }
      }
      `),
      }).subscribe(({ data }) => {
        if (data.UpdatePayment != null) {
          // this.user = data.IntroduceProduct;
          action.do(0, data.UpdatePayment);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public introduceAccount(type: number,
    image: string, name: string,
    note: string,
    action: Action) {
    this.apollo
      .mutate<Response>({
        mutation: parse(`
      mutation {
        IntroduceAccount(
          typpe: ${type},
          image: ${this.stringred(image)}
          ,name: ${this.stringred(name)},note: ${this.stringred(note)}
          ,idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
          id
          typpe
          name
          note
          image
          ${this.basegql}
        }
      }
      `),
      }).subscribe(({ data }) => {
        if (data.IntroduceAccount != null) {
          // this.user = data.IntroduceAccount;
          action.do(0, data.IntroduceAccount);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public updateAccount(id: number, type: number,
    image: string, name: string,
    note: string, action: Action) {

    this.apollo
      .mutate<Response>({
        mutation: parse(`
      mutation {
        UpdateAccount(
          id: ${id},typpe: ${type},
          image: ${this.stringred(image)}
          ,name: ${this.stringred(name)},note: ${this.stringred(note)}
          ,idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
            id
            typpe
            name
            note
            image
            ${this.basegql}
          }
      }
      `),
      }).subscribe(({ data }) => {
        if (data.UpdateAccount != null) {
          // this.user = data.IntroduceProduct;
          action.do(0, data.UpdateAccount);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }

  public introduceExpense(type: number, jhd: number,
    amount: number, description: string, dateof: string, account: number, title: string,
    note: string
    ,
    action: Action) {
    this.apollo
      .mutate<Response>({
        mutation: parse(`
      mutation {
        IntroduceExpense(
          typpe: ${type},jhd: ${jhd},
          amount: ${amount},dateof: ${this.stringred(dateof)},account:${account}
          ,title: ${this.stringred(title)},description: ${this.stringred(description)},note: ${this.stringred(note)}
          ,idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
          id
          typpe
          account
          jhd
          amount
          note
          dateof
          title
          description
        ${this.basegql}
        }
      }
      `),
      }).subscribe(({ data }) => {
        if (data.IntroduceExpense != null) {
          // this.user = data.IntroduceProduct;
          action.do(0, data.IntroduceExpense);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public updateExpense(id: number, type: number, jhd: number,
    amount: number, description: string, dateof: string, account: number, title: string,
    note: string, action: Action) {
    this.apollo
      .mutate<Response>({
        mutation: parse(`
      mutation {
        UpdateExpense(
          id: ${id},typpe: ${type},jhd: ${jhd},
          amount: ${amount},dateof: ${this.stringred(dateof)},account:${account}
          ,title: ${this.stringred(title)},description: ${this.stringred(description)},note: ${this.stringred(note)}
          ,idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
            id
            typpe
            account
            jhd
            amount
            note
            dateof
            title
            description
            ${this.basegql}
          }
      }
      `),
      }).subscribe(({ data }) => {
        if (data.UpdateExpense != null) {
          // this.user = data.UpdateExpense;
          action.do(0, data.UpdateExpense);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public introduceTransaction(account1: number, account2: number,
    amount: number, dateof: string, title: string,
    note: string, state: number, euro: number,
    jhd: number,
    vattype: number,
    vatperc: number,
    vat: number,
    ht: number,
    action: Action) {
    this.apollo
      .mutate<Response>({
        mutation: parse(`
      mutation {
        IntroduceTransaction(
          account1: ${account1},account2: ${account2},
          amount: ${amount},dateof: ${this.stringred(dateof)}
          ,title: ${this.stringred(title)},note: ${this.stringred(note)}
          ,state: ${state}
          ,euro: ${euro}
          ,vattype: ${vattype}
          ,jhd: ${jhd}
          ,vatperc: ${vatperc}
          ,vat: ${vat}
          ,ht: ${ht}
          ,idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
          id
          account1
          account2
          amount
          title
          note
          dateof
          state	
          euro
          jhd	
          vattype  
          vatperc
          vat
          ht          
          ${this.basegql}
        }
      }
      `),
      }).subscribe(({ data }) => {
        if (data.IntroduceTransaction != null) {
          // this.user = data.introduceTransaction;
          action.do(0, data.IntroduceTransaction);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public updateTransaction(id: number, account1: number, account2: number,
    amount: number, dateof: string, title: string,
    note: string, state: number, euro: number,
    jhd: number,
    vattype: number,
    vatperc: number,
    vat: number,
    ht: number,
    action: Action) {
    this.apollo
      .mutate<Response>({
        mutation: parse(`
      mutation {
        UpdateTransaction(
          id: ${id},account1: ${account1},account2: ${account2},
          amount: ${amount},dateof: ${this.stringred(dateof)}
          ,title: ${this.stringred(title)},note: ${this.stringred(note)}
          ,state: ${state}
          ,euro: ${euro}
          ,jhd: ${jhd}
          ,vattype: ${vattype}
          ,vatperc: ${vatperc}
          ,vat: ${vat}
          ,ht: ${ht}
          ,idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
            id
            account1
            account2
            amount
            title
            note
            dateof
            state	
            jhd  
            vattype  
            vatperc
            vat
            ht            
            ${this.basegql}
          }
      }
      `),
      }).subscribe(({ data }) => {
        if (data.UpdateTransaction != null) {
          // this.user = data.updateTransaction;
          action.do(0, data.UpdateTransaction);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public introduceJournalline(jli_number: number, jli_itemno: number, jli_qty: number,
    qtf: number, carton: number, warehouse: number,
    jli_packsize: number, jli_uprice: number, jli_vatper: number,
    jli_vatval: number, jli_discpec: number, jli_discval: number, jli_lineval: number,
    totaloffert: number, jhd_kind: number, norder: number, serial: string,
    showdescription: number, description: string,
    action: Action) {
    let jli_discpecn = +jli_discpec || 0;
    this.apollo
      .mutate<Response>({
        mutation: parse(`
      mutation {
        IntroduceJournalline(
          jli_number: ${jli_number},jli_itemno: ${jli_itemno},jli_qty: ${jli_qty},
          qtf: ${qtf},carton: ${carton},warehouse: ${warehouse},
          jli_packsize: ${jli_packsize},jli_uprice: ${jli_uprice},jli_vatper: ${jli_vatper},
          jli_vatval: ${jli_vatval},jli_discpec: ${jli_discpecn},jli_discval: ${jli_discval},
          jli_lineval: ${jli_lineval},totaloffert: ${totaloffert},jhd_kind: ${jhd_kind},
          norder: ${norder},serial: ${this.stringred(serial)},
          showdescription: ${showdescription},description: ${this.stringred(description)},
        idcompany:${this.idcompany},pincompany:"${this.pincompany}"
) {
          id
          jli_number
          jli_itemno
          jli_qty
          qtf
          carton
          warehouse
          jli_packsize
          jli_uprice
          jli_vatper
          jli_vatval
          jli_discpec
          jli_discval
          jli_lineval
          totaloffert
          jhd_kind
          norder
          serial
          showdescription
          description
        }
      }
      `),
      }).subscribe(({ data }) => {
        if (data.IntroduceJournalline != null) {
          // this.user = data.IntroduceProduct;
          action.do(0, data.IntroduceJournalline);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public updateJournalline(id: number, jli_number: number, jli_itemno: number, jli_qty: number,
    qtf: number, carton: number, warehouse: number,
    jli_packsize: number, jli_uprice: number, jli_vatper: number, jli_vatval: number,
    jli_discpec: number, jli_discval: number, jli_lineval: number, totaloffert: number,
    jhd_kind: number, norder: number, serial: string,
    showdescription: number, description: string,
    action: Action) {
    let jli_discpecn = +jli_discpec || 0;
    this.apollo
      .mutate<Response>({
        mutation: parse(`
      mutation {
        UpdateJournalline(
          id: ${id},jli_number: ${jli_number},jli_itemno: ${jli_itemno},jli_qty: ${jli_qty},
          qtf: ${qtf},carton: ${carton},warehouse: ${warehouse},
          jli_packsize: ${jli_packsize},jli_uprice: ${jli_uprice},jli_vatper: ${jli_vatper},
          jli_vatval: ${jli_vatval},jli_discpec: ${jli_discpecn},jli_discval: ${jli_discval},
          jli_lineval: ${jli_lineval},totaloffert: ${totaloffert},jhd_kind: ${jhd_kind},
          norder: ${norder},serial: ${this.stringred(serial)},
          showdescription: ${showdescription},description: ${this.stringred(description)},
        idcompany:${this.idcompany},pincompany:"${this.pincompany}"
) {
          id
          jli_number
          jli_itemno
          jli_qty
          qtf
          carton
          warehouse
          jli_packsize
          jli_uprice
          jli_vatper
          jli_vatval
          jli_discpec
          jli_discval
          jli_lineval
          totaloffert      
          norder
          serial
          showdescription
          description

        }
      }
      `),
      }).subscribe(({ data }) => {
        if (data.UpdateJournalline != null) {
          // this.user = data.IntroduceProduct;
          action.do(0, data.UpdateJournalline);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public introduceJournalkind(jki_name: string, jki_module: string, jki_qty: number, jki_val: number, action: Action) {
    this.apollo
      .mutate<Response>({
        mutation: parse(`
      mutation {
        IntroduceJournalkind(jki_name: ${this.stringred(jki_name)},jki_module: ${this.stringred(jki_module)},jki_qty: ${jki_qty},jki_val: ${jki_val}) {
          id
          jki_name
          jki_module
          jki_qty
          jki_val
        }
      }
      `),
      }).subscribe(({ data }) => {
        if (data.IntroduceJournalkind != null) {
          // this.user = data.IntroduceJournalkind;
          action.do(0, data.IntroduceJournalkind);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public updateJournalkind(id: number, jki_name: string, jki_module: string, jki_qty: number, jki_val: number, action: Action) {
    this.apollo
      .mutate<Response>({
        mutation: parse(`
      mutation {
        UpdateJournalkind(id: ${id},jki_name: ${this.stringred(jki_name)},jki_module: ${this.stringred(jki_module)},jki_qty: ${jki_qty},jki_val: ${jki_val}) {
          id
          jki_name
          jki_module
          jki_qty
          jki_val
        }
      }
      `),
      }).subscribe(({ data }) => {
        if (data.UpdateJournalkind != null) {
          // this.user = data.IntroduceJournalkind;
          action.do(0, data.UpdateJournalkind);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public introduceWarehouse(war_name: string, war_adress: string, action: Action) {
    this.apollo
      .mutate<Response>({
        mutation: parse(`
      mutation {
        IntroduceWarehouse(war_name: ${this.stringred(war_name)},war_adress: ${this.stringred(war_adress)},
        idcompany:${this.idcompany},pincompany:"${this.pincompany}"
) {
          id
          war_name
          war_adress
        }
      }
      `),
      }).subscribe(({ data }) => {
        if (data.IntroduceWarehouse != null) {
          // this.user = data.IntroduceWarehouse;
          action.do(0, data.IntroduceWarehouse);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public updateWarehouse(id: number, war_name: string, war_adress: string, action: Action) {
    this.apollo
      .mutate<Response>({
        mutation: parse(`
      mutation {
        UpdateWarehouse(id: ${id},war_name: ${this.stringred(war_name)},war_adress: ${this.stringred(war_adress)},
        idcompany:${this.idcompany},pincompany:"${this.pincompany}"
) {
          id
          war_name
          war_adress
        }
      }
      `),
      }).subscribe(({ data }) => {
        if (data.UpdateWarehouse != null) {
          // this.user = data.IntroduceWarehouse;
          action.do(0, data.UpdateWarehouse);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public introduceParty(party_name: string, party_type: number,
    party_adress: string, party_code: string, party_city: string,
    party_title: string, party_firstname: string, party_secondname: string,
    party_job: string, party_tel: string, party_email: string, image: string,
    rcs: string,
    na: string,
    mfn: string,
    centre: string,
    stade: number,
    age: number,
    adherent: number,
    surface: string,
    refrent: string,
    agr: string,
    datefcontact: string, daterelance: string,
    dateprevu: string, priority: number,
    mobile1: string,
    mobile2: string,
    nis: string,
    v1: number,
    v2: number,
    w1: number,
    w2: number,
    interest: string,
    etat: number,
    goal: number,
    trash: number,
    agent: number,
    action: Action) {
    if (!stade) stade = 0;
    if (!age) age = 0;
    if (!adherent) adherent = 100;

    this.apollo
      .mutate<Response>({
        mutation: parse(`
      mutation {
        IntroduceParty(party_name: ${this.stringred(party_name)},party_type: ${party_type}
        ,party_adress: ${this.stringred(party_adress)},
        party_code:${this.stringred(party_code)}, party_city:${this.stringred(party_city)},
        party_title: ${this.stringred(party_title)},party_firstname: ${this.stringred(party_firstname)},
        party_secondname: ${this.stringred(party_secondname)}, party_job: ${this.stringred(party_job)},
        party_tel: ${this.stringred(party_tel)}, party_email: ${this.stringred(party_email)},
        image: ${this.stringred(image)},
        rcs:${this.stringred(rcs)},
        na:${this.stringred(na)},
        mfn:${this.stringred(mfn)},
        centre:${this.stringred(centre)},
        stade:${stade},
        age:${age},
        adherent:${adherent},
        surface:${this.stringred(surface)},
        refrent:${this.stringred(refrent)},
        agr:${this.stringred(agr)},
        datefcontact:${this.stringred(datefcontact)},daterelance:${this.stringred(daterelance)},
        dateprevu:${this.stringred(dateprevu)},priority:${priority},
        mobile1:${this.stringred(mobile1)},
        mobile2:${this.stringred(mobile2)},
        nis:${this.stringred(nis)},
        v1:${v1},
        v2:${v2},
        w1:${w1},
        w2:${w2},
        interest:${this.stringred(interest)},
        etat:${etat},
        goal:${goal},
        trash:${trash},
        agent:${agent},
        idcompany:${this.idcompany},pincompany:"${this.pincompany}"
) {
          id
          party_name
          party_type
          party_adress
          party_code
          party_city
          party_title
          party_firstname
          party_secondname
          party_job
          party_tel
          party_email
          image
          rcs
          na
          mfn
          centre
          stade
          age
          adherent
          surface
          refrent
          agr
          datefcontact
          daterelance
          dateprevu
          priority
          mobile1
          mobile2
          nis
          v1
          v2
          w1
          w2
          interest
          etat
          goal
          trash
          agent
          

        }
      }
      `),
      }).subscribe(({ data }) => {

        if (data.IntroduceParty != null) {
          // this.user = data.IntroduceParty;
          action.do(0, data.IntroduceParty);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public updateParty(id: number, party_name: string, party_type: number,
    party_adress: string, party_code: string, party_city: string,
    party_title: string, party_firstname: string, party_secondname: string,
    party_job: string, party_tel: string, party_email: string,
    image: string,
    rcs: string,
    na: string,
    mfn: string,
    centre: string,
    stade: number,
    age: number,
    adherent: number,
    surface: string,
    refrent: string,
    agr: string,
    datefcontact: string, daterelance: string,
    dateprevu: string, priority: number,
    mobile1: string,
    mobile2: string,
    nis: string,
    v1: number,
    v2: number,
    w1: number,
    w2: number,
    interest: string,
    etat: number,
    goal: number,
    trash: number,
    agent: number,
    action: Action) {
    this.apollo
      .mutate<Response>({
        mutation: parse(`
      mutation {
        UpdateParty(id: ${id},party_name: ${this.stringred(party_name)},party_type: ${party_type},party_adress: ${this.stringred(party_adress)},
        party_code:${this.stringred(party_code)}, party_city:${this.stringred(party_city)},
        party_title: ${this.stringred(party_title)},party_firstname: ${this.stringred(party_firstname)},
        party_secondname: ${this.stringred(party_secondname)}, party_job: ${this.stringred(party_job)},
        party_tel: ${this.stringred(party_tel)}, party_email: ${this.stringred(party_email)},
        image: ${this.stringred(image)},
        rcs:${this.stringred(rcs)},
        na:${this.stringred(na)},
        mfn:${this.stringred(mfn)},
        centre:${this.stringred(centre)},
        stade:${stade},
        age:${age},
        adherent:${adherent},
        surface:${this.stringred(surface)},
        refrent:${this.stringred(refrent)},
        agr:${this.stringred(agr)},
        datefcontact:${this.stringred(datefcontact)},daterelance:${this.stringred(daterelance)},
        dateprevu:${this.stringred(dateprevu)},priority:${priority},
        mobile1:${this.stringred(mobile1)},
        mobile2:${this.stringred(mobile2)},
        nis:${this.stringred(nis)},
        v1:${v1},
        v2:${v2},
        w1:${w1},
        w2:${w2},
        interest:${this.stringred(interest)},
        etat:${etat},
        goal:${goal},
        trash:${trash},
        agent:${agent},
        idcompany:${this.idcompany},pincompany:"${this.pincompany}"
) {
          id
          party_name
          party_type
          party_adress
          party_code
          party_city
          party_title
          party_firstname
          party_secondname
          party_job
          party_tel
          party_email
          image
          rcs
          na
          mfn
          centre
          stade
          age
          adherent
          surface
          refrent
          agr
          datefcontact
          daterelance
          dateprevu
          priority
          mobile1
          mobile2
          nis
          v1
          v2
          w1
          w2
          interest
          etat
          goal
          trash
          agent          

        }
      }
      `),
      }).subscribe(({ data }) => {
        if (data.UpdateParty != null) {
          // this.user = data.IntroduceParty;
          action.do(0, data.UpdateParty);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public introduceEnvelope(name: string, remise1: number, remise2: number, action: Action) {
    this.apollo
      .mutate<Response>({
        mutation: parse(`
      mutation {
        IntroduceEnvelope(name: ${this.stringred(name)},remise1: ${remise1},remise2: ${remise2}) {
          id
          name
          remise1
          remise2
        }
      }
      `),
      }).subscribe(({ data }) => {
        if (data.IntroduceEnvelope != null) {

          action.do(0, data.IntroduceEnvelope);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public updateEnvelope(id: number, name: string, remise1: number, remise2: number, action: Action) {
    this.apollo
      .mutate<Response>({
        mutation: parse(`
      mutation {
        UpdateEnvelope(id: ${id},name: ${this.stringred(name)},remise1: ${remise1},remise2: ${remise2}) {
          id
          name
          remise1
          remise2
        }
      }
      `),
      }).subscribe(({ data }) => {
        if (data.UpdateEnvelope != null) {
          // this.user = data.IntroduceProduct;
          action.do(0, data.UpdateEnvelope);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }

  logout() {
    this._cookieService.remove('user');
    this.user = null;
    setTimeout(() => {
      this.router.navigate(['/login']);
    }, 2000);


  }
  public getAgentsAll(action: Action) {
    this.apollo
      .watchQuery<Response>({
        query: gql`
      {agent_list(limit:50000) {
        agents {
          id
          firstname
          secondname
          typeagent
          email
          password
          image
          name
          targett
          selectall
          attached
          rights
          idcompany
          pincompany
        }
      }}
      `, fetchPolicy: 'no-cache'
      })
      .valueChanges.subscribe(result => {

        action.do(0, result);

      });
  }
  public getAgents(action: Action) {
    this.apollo
      .watchQuery<Response>({
        query: gql`
      {agent_list(limit:50000,idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        agents {
          id
          firstname
          secondname
          typeagent
          email
          password
          image
          name
          targett
          selectall
          attached
          rights
          idcompany
          pincompany
        }
      }}
      `, fetchPolicy: 'no-cache'
      })
      .valueChanges.subscribe(result => {

        action.do(0, result);

      });
  }
  public getEnvelopes(action: Action) {
    this.apollo
      .watchQuery<Response>({
        query: gql`
      {envelope_list(limit:50000) {
        envelopes {
          id
          name
          remise1
          remise2
        }
      }}
      `, fetchPolicy: 'no-cache'
      })
      .valueChanges.subscribe(result => {

        action.do(0, result);

      });


  }
  public deleteAgent(idn: number, action: Action) {
    this.apollo.mutate({
      mutation: gql`
      mutation( $id:Int!) {
        DeleteAgent(id: $id)
        {
          error
          message
        }
      }
      `,
      variables: {
        id: idn
      }
    }).subscribe(({ data }) => {

      action.do(data['DeleteAgent'].error);
    }, (error) => {
      console.log('there was an error sending the query', error);
    });

  }

  public introduceAgent(firstname: string, secondname: string, typeagent: number, email: string, password: string, image: string, name: string, targett: number, selectall: boolean, attached: string, rights: string, rooted, action: Action) {
    let idcompany = this.idcompany;
    let pincompany = this.pincompany;

    if (rooted) {
      idcompany = null;
      pincompany = null;
    }
    this.apollo
      .mutate<Response>({
        mutation: parse(`
      mutation {
        IntroduceAgent(firstname: ${this.stringred(firstname)},secondname: ${this.stringred(secondname)},
        typeagent: ${typeagent},email: ${this.stringred(email)},password: ${this.stringred(password)},
        image: ${this.stringred(image)},name: ${this.stringred(name)},targett: ${targett},
        selectall: ${selectall},attached: ${this.stringred(attached)},rights: ${this.stringred(rights)}
        ,idcompany:${idcompany},pincompany:"${pincompany}") {
          id
          firstname
          secondname
          typeagent
          email
          password
          image
          name
          targett
          selectall
          attached
          rights
          idcompany
          pincompany
        }
      }
      `),
      }).subscribe(({ data }) => {
        if (data.IntroduceAgent != null) {
          // this.user = data.IntroduceAgent;
          action.do(0, data.IntroduceAgent);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  // tslint:disable-next-line: max-line-length
  public updateAgent(id: number, firstname: string, secondname: string, typeagent: number, email: string, password: string, image: string, name: string, targett: number, selectall: boolean, attached: string, rights: string, action: Action) {

    this.apollo
      .mutate<Response>({
        mutation: parse(`
      mutation {
        UpdateAgent(id: ${id},firstname: ${this.stringred(firstname)},secondname: ${this.stringred(secondname)},
        typeagent: ${typeagent},email: ${this.stringred(email)},password: ${this.stringred(password)},
        image: ${this.stringred(image)},name: ${this.stringred(name)},targett: ${targett},
        selectall: ${selectall},attached: ${this.stringred(attached)},rights: ${this.stringred(rights)}
        ,idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
          id
          firstname
          secondname
          typeagent
          email
          password
          image
          name
          targett
          selectall
          attached
          rights
          idcompany
          pincompany
        }
      }
      `),
      }).subscribe(({ data }) => {
        if (data.UpdateAgent != null) {
          // this.user = data.IntroduceAgent;

          action.do(0, data.UpdateAgent);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  // ----------------------------------------------------
  public upload(files: FileList, action: Action) {
    return this.apollo
      .mutate<Response>({
        mutation: gql`
      mutation singleUpload($file: Upload!) {
        singleUpload(file: $file) {
          image
        }
      }
    `,
        variables: {
          file: files[0]
        }, context: { useMultipart: true }
      }).subscribe(({ data }) => {
        if (data.singleUpload != null) {
          action.do(0, data.singleUpload);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });


  }
  // -----------------------------------------------------
  public deleteEtape(idn: number, action: Action) {
    this.apollo.mutate({
      mutation: gql`
    mutation( $id:Int!) {
      DeleteEtape(id: $id)
      {
        error
        message
      }
    }
    `,
      variables: {
        id: idn
      }
    }).subscribe(({ data }) => {

      action.do(data['DeleteEtape'].error);
    }, (error) => {
      console.log('there was an error sending the query', error);
    });

  }
  public deleteCategory(idn: number, action: Action) {
    this.apollo.mutate({
      mutation: gql`
    mutation( $id:Int!,$idcompany:Int,$pincompany:String
      ) {
      DeleteCategory(id: $id,idcompany: $idcompany,pincompany: $pincompany
        )
      {
        error
        message
      }
    }
    `,
      variables: {
        id: idn,
        idcompany: this.idcompany,
        pincompany: this.pincompany
      }
    }).subscribe(({ data }) => {

      action.do(data['DeleteCategory'].error);
    }, (error) => {
      console.log('there was an error sending the query', error);
    });

  }
  public deleteUsercomp(idn: number, action: Action) {
    this.apollo.mutate({
      mutation: gql`
    mutation( $id:Int!) {
      DeleteUsercomp(id: $id)
      {
        error
        message
      }
    }
    `,
      variables: {
        id: idn
      }
    }).subscribe(({ data }) => {

      action.do(data['DeleteUsercomp'].error);
    }, (error) => {
      console.log('there was an error sending the query', error);
    });

  }
  public deleteTask(idn: number, action: Action) {
    this.apollo.mutate({
      mutation: gql`
    mutation( $id:Int!,$idcompany:Int,$pincompany:String
      ) {
      DeleteTask(id: $id,idcompany: $idcompany,pincompany: $pincompany
        )
      {
        error
        message
      }
    }
    `,
      variables: {
        id: idn,
        idcompany: this.idcompany,
        pincompany: this.pincompany
      }
    }).subscribe(({ data }) => {

      action.do(data['DeleteTask'].error);
    }, (error) => {
      console.log('there was an error sending the query', error);
    });

  }
  public deleteCompany(idn: number, action: Action) {
    this.apollo.mutate({
      mutation: gql`
    mutation( $id:Int!) {
      DeleteCompany(id: $id)
      {
        error
        message
      }
    }
    `,
      variables: {
        id: idn
      }
    }).subscribe(({ data }) => {

      action.do(data['DeleteCompany'].error);
    }, (error) => {
      console.log('there was an error sending the query', error);
    });

  }

  public deleteAccountcategory(idn: number, action: Action) {
    this.apollo.mutate({
      mutation: gql`
    mutation( $id:Int!) {
      DeleteAccountcategory(id: $id)
      {
        error
        message
      }
    }
    `,
      variables: {
        id: idn
      }
    }).subscribe(({ data }) => {

      action.do(data['DeleteAccountcategory'].error);
    }, (error) => {
      console.log('there was an error sending the query', error);
    });

  }
  public deleteAccounttype(idn: number, action: Action) {
    this.apollo.mutate({
      mutation: gql`
    mutation( $id:Int!) {
      DeleteAccounttype(id: $id)
      {
        error
        message
      }
    }
    `,
      variables: {
        id: idn
      }
    }).subscribe(({ data }) => {

      action.do(data['DeleteAccounttype'].error);
    }, (error) => {
      console.log('there was an error sending the query', error);
    });

  }

  public introduceEtape(name: string, action: Action) {
    this.apollo
      .mutate<Response>({
        mutation: parse(`
    mutation {
      IntroduceEtape(name: ${this.stringred(name)}) {
        id
        name
      }
    }
    `),
      }).subscribe(({ data }) => {
        if (data.IntroduceEtape != null) {

          action.do(0, data.IntroduceEtape);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public updateEtape(id: number, name: string, action: Action) {
    this.apollo
      .mutate<Response>({
        mutation: parse(`
    mutation {
      UpdateEtape(id: ${id},name: ${this.stringred(name)}) {
        id
        name
      }
    }
    `),
      }).subscribe(({ data }) => {
        if (data.UpdateEtape != null) {
          // this.user = data.IntroduceProduct;
          action.do(0, data.UpdateEtape);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public getEtapes(action: Action) {
    this.apollo
      .watchQuery<Response>({
        query: gql`
    {etape_list(limit:50000) {
      etapes {
        id
        name
      }
    }}
    `, fetchPolicy: 'no-cache'
      })
      .valueChanges.subscribe(result => {

        action.do(0, result);

      });


  }
  public introduceCategory(name: string, image: string, action: Action) {

    this.apollo
      .mutate<Response>({
        mutation: parse(`
    mutation {
      IntroduceCategory(name: ${this.stringred(name)},image: ${this.stringred(image)}
      ,idcompany:${this.idcompany},pincompany:"${this.pincompany}"
      ) {
        id
        name
        image
      }
    }
    `),
      }).subscribe(({ data }) => {
        if (data.IntroduceCategory != null) {

          action.do(0, data.IntroduceCategory);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public updateCategory(id: number, name: string, image: string, action: Action) {
    this.apollo
      .mutate<Response>({
        mutation: parse(`
    mutation {
      UpdateCategory(id: ${id},name: ${this.stringred(name)},image: ${this.stringred(image)}
      ,idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        id
        name
        image
      }
    }
    `),
      }).subscribe(({ data }) => {
        if (data.UpdateCategory != null) {
          // this.user = data.IntroduceProduct;
          action.do(0, data.UpdateCategory);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public introduceTask(task: Task, action: Action) {

    this.apollo
      .mutate<Response>({
        mutation: parse(`
    mutation {
      IntroduceTask(
        doccontext:${task.doccontext},
        docid:${task.docid},
        title:${this.stringred(task.title)},
        note:${this.stringred(task.note)},
        file:${this.stringred(task.file)},
        datestart:${this.stringred(task.datestart)},
        dateend:${this.stringred(task.dateend)},
        reserved:${task.reserved},
        created_by:${task.created_by},
        created_byname:${this.stringred(task.created_byname)},
        created_at:${this.stringred(task.created_at)},
        terminated_at:${this.stringred(task.terminated_at)},
        terminated_by:${task.terminated_by},
        terminated_byname:${this.stringred(task.terminated_byname)},
        typpe:${task.typpe},
        source:${this.stringred(task.source)},
        destination:${this.stringred(task.destination)},
        idcontact:${task.idcontact},
        amount:${task.amount},
        terminated_amount:${task.terminated_amount}        
      ,idcompany:${this.idcompany},pincompany:"${this.pincompany}"
      ) {
        id
        doccontext
        docid
        title
        note
        file
        datestart
        dateend
        reserved
        created_by
        created_byname
        created_at
        terminated_at
        terminated_by
        terminated_byname
        typpe
        source
        destination
        idcontact
        amount
        terminated_amount
        idcompany
        pincompany
      }
    }
    `),
      }).subscribe(({ data }) => {
        if (data.IntroduceTask != null) {

          action.do(0, data.IntroduceTask);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public updateTask(task: Task, action: Action) {
    this.apollo
      .mutate<Response>({
        mutation: parse(`
    mutation {
      UpdateTask(    
        id:${task.id}    
        doccontext:${task.doccontext},
        docid:${task.docid},
        title:${this.stringred(task.title)},
        note:${this.stringred(task.note)},
        file:${this.stringred(task.file)},
        datestart:${this.stringred(task.datestart)},
        dateend:${this.stringred(task.dateend)},
        reserved:${task.reserved},
        created_by:${task.created_by},
        created_byname:${this.stringred(task.created_byname)},
        created_at:${this.stringred(task.created_at)},
        terminated_at:${this.stringred(task.terminated_at)},
        terminated_by:${task.terminated_by},
        terminated_byname:${this.stringred(task.terminated_byname)},
        typpe:${task.typpe},
        source:${this.stringred(task.source)},
        destination:${this.stringred(task.destination)},
        idcontact:${task.idcontact},
        amount:${task.amount},
        terminated_amount:${task.terminated_amount}
      ,idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        id
        doccontext
        docid
        title
        note
        file
        datestart
        dateend
        reserved
        created_by
        created_byname
        created_at
        terminated_at
        terminated_by
        terminated_byname
        typpe
        source
        destination
        idcontact
        amount
        terminated_amount
        idcompany
        pincompany
      }
    }
    `),
      }).subscribe(({ data }) => {
        if (data.UpdateTask != null) {
          // this.user = data.IntroduceProduct;
          action.do(0, data.UpdateTask);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public introduceUsercomp(userid: number, companyid: number, typpe: number, action: Action) {
    this.apollo
      .mutate<Response>({
        mutation: parse(`
    mutation {
      IntroduceUsercomp(userid: ${userid},companyid: ${companyid},typpe: ${typpe}) {
        id
        userid
        companyid
        typpe
        email
      }
    }
    `),
      }).subscribe(({ data }) => {
        if (data.IntroduceUsercomp != null) {

          action.do(0, data.IntroduceUsercomp);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public updateUsercomp(id: number, userid: number, companyid: number, typpe: number, action: Action) {
    this.apollo
      .mutate<Response>({
        mutation: parse(`
    mutation {
      UpdateUsercomp(id: ${id},userid: ${userid},companyid: ${companyid},typpe: ${typpe}) {
        id
        userid
        companyid
        typpe
        email
      }
    }
    `),
      }).subscribe(({ data }) => {
        if (data.UpdateUsercomp != null) {
          // this.user = data.IntroduceProduct;
          action.do(0, data.UpdateUsercomp);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public introduceCompany(name: string, adress: string, ville: string,
    codepostal: string, country: number, image: string, ibanname: string,
    iban: string, ibanbank: string, bic: string, siret: string, tvan: string,
    rcs: string, tel: string, mail: string, pincompany: string, capital: string, article: string, fax: string,
    ibanagence: string, sirett: string, tvant: string,
    rcst: string, bict: string, tvadefault: number,
    tvadefaults: string, negativeqt: number, negativeqtpass: string,
    currency: string, currencycent: string, currencytext: string,
    currencysymbol: string, root: number, multistock: number, timbre: number,
    cartonon: number,
    offert: number,
    slogan: string, agreement: string, action: Action) {
    this.apollo
      .mutate<Response>({
        mutation: parse(`
    mutation {
      IntroduceCompany(name: ${this.stringred(name)},adress:${this.stringred(adress)},
      ville: ${this.stringred(ville)},codepostal: ${this.stringred(codepostal)},
      country: ${country},image: ${this.stringred(image)},
      ibanname: ${this.stringred(ibanname)},iban: ${this.stringred(iban)},
      ibanbank: ${this.stringred(ibanbank)},bic: ${this.stringred(bic)},
      siret: ${this.stringred(siret)},tvan: ${this.stringred(tvan)},
      rcs: ${this.stringred(rcs)},tel: ${this.stringred(tel)},
      mail: ${this.stringred(mail)},pincompany: ${this.stringred(pincompany)}
      ,capital:${this.stringred(capital)},article:${this.stringred(this.filterminus(article))},
      fax:${this.stringred(fax)},ibanagence:${this.stringred(ibanagence)},
      sirett:${this.stringred(sirett)},tvant:${this.stringred(tvant)},
      rcst:${this.stringred(rcst)},bict:${this.stringred(bict)},
      tvadefault:${tvadefault},tvadefaults:${this.stringred(tvadefaults)},
      negativeqt:${negativeqt},negativeqtpass:${this.stringred(negativeqtpass)},
      currency:${this.stringred(currency)},currencycent:${this.stringred(currencycent)},
      currencytext:${this.stringred(currencytext)},currencysymbol:${this.stringred(currencysymbol)},
      root:${root},multistock:${multistock},timbre:${timbre},cartonon:${cartonon},
      offert:${offert},slogan:${this.stringred(slogan)},agreement:${this.stringred(agreement)}) {
        id
        name
        adress
        ville
        codepostal
        country
        image
        ibanname
        iban
        ibanbank
        bic
        siret
        tvan
        rcs
        tel
        mail
        pincompany
        capital
        article
        fax
        ibanagence
        sirett
        tvant
        rcst
        bict
        tvadefault
        tvadefaults
        negativeqt
        negativeqtpass
        currency
        currencycent
        currencytext
        currencysymbol
        root
        multistock
        timbre
        cartonon 
        offert
        slogan
        agreement       

              }
    }
    `),
      }).subscribe(({ data }) => {
        if (data.IntroduceCompany != null) {

          action.do(0, data.IntroduceCompany);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public updateCompany(id: number, name: string, adress: string, ville: string,
    codepostal: string, country: number, image: string, ibanname: string,
    iban: string, ibanbank: string, bic: string, siret: string, tvan: string,
    rcs: string, tel: string, mail: string, pincompany: string, capital: string, article: string, fax: string,
    ibanagence: string, sirett: string, tvant: string,
    rcst: string, bict: string, tvadefault: number,
    tvadefaults: string, negativeqt: number, negativeqtpass: string,
    currency: string, currencycent: string, currencytext: string,
    currencysymbol: string, root: number, multistock: number, timbre: number,
    cartonon: number, offert: number,
    slogan: string, agreement: string, action: Action) {

    this.apollo
      .mutate<Response>({
        mutation: parse(`
    mutation {
      UpdateCompany(id: ${id},name: ${this.stringred(name)},adress:${this.stringred(adress)},
      ville: ${this.stringred(ville)},codepostal: ${this.stringred(codepostal)},
      country: ${country},image: ${this.stringred(image)},
      ibanname: ${this.stringred(ibanname)},iban: ${this.stringred(iban)},
      ibanbank: ${this.stringred(ibanbank)},bic: ${this.stringred(bic)},
      siret: ${this.stringred(siret)},tvan: ${this.stringred(tvan)},
      rcs: ${this.stringred(rcs)},tel: ${this.stringred(tel)},
      mail: ${this.stringred(mail)},pincompany: ${this.stringred(pincompany)}
      ,capital:${this.stringred(capital)},article:${this.stringred(this.filterminus(article))},
      fax:${this.stringred(fax)},ibanagence:${this.stringred(ibanagence)},
      sirett:${this.stringred(sirett)},tvant:${this.stringred(tvant)},
      rcst:${this.stringred(rcst)},bict:${this.stringred(bict)},
      tvadefault:${tvadefault},tvadefaults:${this.stringred(tvadefaults)},
      negativeqt:${negativeqt},negativeqtpass:${this.stringred(negativeqtpass)},
      currency:${this.stringred(currency)},currencycent:${this.stringred(currencycent)},
      currencytext:${this.stringred(currencytext)},currencysymbol:${this.stringred(currencysymbol)},
      root:${root},multistock:${multistock},timbre:${timbre},cartonon:${cartonon},offert:${offert}
      ,slogan:${this.stringred(slogan)},agreement:${this.stringred(agreement)}) {
        id
        name
        adress
        ville
        codepostal
        country
        image
        ibanname
        iban
        ibanbank
        bic
        siret
        tvan
        rcs
        capital
        article
        fax
        ibanagence
        sirett
        tvant
        rcst
        bict
        tvadefault
        tvadefaults
        negativeqt
        negativeqtpass
        currency
        currencycent
        currencytext
        currencysymbol
        root
        multistock
        timbre
        cartonon   
        offert     
        slogan
        agreement       
        tel
        mail
        pincompany
      }
    }
    `),
      }).subscribe(({ data }) => {
        if (data.UpdateCompany != null) {
          // this.user = data.IntroduceProduct;
          action.do(0, data.UpdateCompany);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public introduceAccountcategory(name: string, parent: number, action: Action) {
    this.apollo
      .mutate<Response>({
        mutation: parse(`
    mutation {
      IntroduceAccountcategory(name: ${this.stringred(name)},parent: ${parent}) {
        id
        name
        parent
      }
    }
    `),
      }).subscribe(({ data }) => {
        if (data.IntroduceAccountcategory != null) {

          action.do(0, data.IntroduceAccountcategory);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public updateAccountcategory(id: number, name: string, parent: number, action: Action) {
    this.apollo
      .mutate<Response>({
        mutation: parse(`
    mutation {
      UpdateAccountcategory(id: ${id},name: ${this.stringred(name)},parent: ${parent}) {
        id
        name
        parent
      }
    }
    `),
      }).subscribe(({ data }) => {
        if (data.UpdateAccountcategory != null) {
          // this.user = data.IntroduceProduct;
          action.do(0, data.UpdateAccountcategory);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }

  public getAccountcategorys(action: Action) {
    this.apollo
      .watchQuery<Response>({
        query: gql`
    {accountcategory_list(limit:50000) {
      accountcategorys {
        id
        name
        parent
      }
    }}
    `, fetchPolicy: 'no-cache'
      })
      .valueChanges.subscribe(result => {

        action.do(0, result);

      });


  }
  public introduceAccounttype(name: string, action: Action) {
    this.apollo
      .mutate<Response>({
        mutation: parse(`
    mutation {
      IntroduceAccounttype(name: ${this.stringred(name)}) {
        id
        name
      }
    }
    `),
      }).subscribe(({ data }) => {
        if (data.IntroduceAccounttype != null) {

          action.do(0, data.IntroduceAccounttype);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public updateAccounttype(id: number, name: string, action: Action) {
    this.apollo
      .mutate<Response>({
        mutation: parse(`
    mutation {
      UpdateAccounttype(id: ${id},name: ${this.stringred(name)}) {
        id
        name
      }
    }
    `),
      }).subscribe(({ data }) => {
        if (data.UpdateAccounttype != null) {
          // this.user = data.IntroduceProduct;
          action.do(0, data.UpdateAccounttype);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }

  public getAccounttypes(action: Action) {
    this.apollo
      .watchQuery<Response>({
        query: gql`
    {accounttype_list(limit:50000) {
      accounttypes {
        id
        name
      }
    }}
    `, fetchPolicy: 'no-cache'
      })
      .valueChanges.subscribe(result => {

        action.do(0, result);

      });


  }
  public getCategorys(action: Action) {
    this.apollo
      .watchQuery<Response>({
        query: gql`
    {category_list(limit:50000,
      idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
      categorys {
        id
        name
        image
      }
    }}
    `, fetchPolicy: 'no-cache'
      })
      .valueChanges.subscribe(result => {

        action.do(0, result);

      });


  }
  public getTasks(doccontext: number, docid, action: Action) {
    this.apollo
      .watchQuery<Response>({
        query: gql`
    {task_list(limit:50000,doccontext:${doccontext},docid:${docid},
      idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        tasks {
        id
        doccontext
        docid
        title
        note
        file
        datestart
        dateend
        reserved
        created_by
        created_byname
        created_at
        terminated_at
        terminated_by
        terminated_byname
        typpe
        source
        destination
        idcontact
        amount
        terminated_amount
        idcompany
        pincompany
      }
    }}
    `, fetchPolicy: 'no-cache'
      })
      .valueChanges.subscribe(result => {

        action.do(0, result);

      });


  }
  public getUsercomps(action: Action) {
    this.apollo
      .watchQuery<Response>({
        query: gql`
    {usercomp_list(limit:50000) {
      usercomps {
        id
        userid
        companyid
        typpe
        email
      }
    }}
    `, fetchPolicy: 'no-cache'
      })
      .valueChanges.subscribe(result => {

        action.do(0, result);

      });


  }
  public getCompanys(action: Action) {
    this.apollo
      .watchQuery<Response>({
        query: gql`
    {company_list(limit:50000) {
      companys {
        id
        name
        adress
        ville
        codepostal
        country
        image
        ibanname
        iban
        ibanbank
        bic
        siret
        tvan
        rcs
        capital
        article
        fax
        ibanagence
        sirett
        tvant
        rcst
        bict
        tvadefault
        tvadefaults
        negativeqt
        negativeqtpass
        currency
        currencycent
        currencytext
        currencysymbol
        root
        multistock
        timbre
        cartonon
        offert   
        slogan
        agreement        
        tel
        mail
        pincompany
      }
    }}
    `, fetchPolicy: 'no-cache'
      })
      .valueChanges.subscribe(result => {

        action.do(0, result);

      });


  }
  public getCompanysandUserComp(action: Action) {
    this.apollo
      .watchQuery<Response>({
        query: gql`
    {company_list(limit:50000) {
      companys {
        id
        name
        adress
        ville
        codepostal
        country
        image
        ibanname
        iban
        ibanbank
        bic
        siret
        tvan
        rcs
        capital
        article
        fax
        ibanagence
        sirett
        tvant
        rcst
        bict
        tvadefault
        tvadefaults
        negativeqt
        negativeqtpass
        currency
        currencycent
        currencytext
        currencysymbol
        root
        multistock
        timbre
        cartonon
        offert    
        slogan
        agreement       
        tel
        mail
        pincompany
      }
    }
    usercomp_list(limit:50000) {
      usercomps {
        id
        userid
        companyid
        typpe
        email
      }
    }
  }
    `, fetchPolicy: 'no-cache'
      })
      .valueChanges.subscribe(result => {

        action.do(0, result);

      });


  }
  public deleteFin(idn: number, action: Action) {
    this.apollo.mutate({
      mutation: gql`
    mutation( $id:Int!) {
      DeleteFin(id: $id)
      {
        error
        message
      }
    }
    `,
      variables: {
        id: idn
      }
    }).subscribe(({ data }) => {

      action.do(data['DeleteFin'].error);
    }, (error) => {
      console.log('there was an error sending the query', error);
    });

  }
  public introduceFin(idcompany, fin: Fin, action: Action) {


    this.apollo
      .mutate<Response>({
        mutation: parse(`
    mutation {
      IntroduceFin(name: ${this.stringred(fin.name)},kind: ${fin.kind},
      ,typpe: ${fin.typpe},image: ${this.stringred(fin.image)},
      note: ${this.stringred(fin.note)} ,parent: ${fin.parent},module: ${fin.module},
      ,nvalue: ${fin.nvalue},fvalue: ${fin.fvalue},svalue: ${this.stringred(fin.svalue)}        
      ,idcompany:${idcompany},pincompany:"${this.pincompany}") {
        id
        name
        kind
        module
        typpe
        parent
        image
        note
        nvalue
        fvalue
        svalue
      }
    }
    `),
      }).subscribe(({ data }) => {
        if (data.IntroduceFin != null) {

          action.do(0, data.IntroduceFin);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public updateFin(idcompany, fin: Fin, action: Action) {
    this.apollo
      .mutate<Response>({
        mutation: parse(`
    mutation {
      UpdateFin(id: ${fin.id},name: ${this.stringred(fin.name)},kind: ${fin.kind},
      ,typpe: ${fin.typpe},image: ${this.stringred(fin.image)},
      note: ${this.stringred(fin.note)} ,parent: ${fin.parent},module: ${fin.module},
      ,nvalue: ${fin.nvalue},fvalue: ${fin.fvalue},svalue: ${this.stringred(fin.svalue)}    
      ,idcompany:${this.idcompany},pincompany:"${this.pincompany}") {
        id
        name
        kind
        typpe
        parent
        module
        image
        note
        nvalue
        fvalue
        svalue
      }
    }
    `),
      }).subscribe(({ data }) => {
        if (data.UpdateFin != null) {
          // this.user = data.IntroduceProduct;
          action.do(0, data.UpdateFin);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public getFins(idcompany, kind: number, module: number, action: Action) {
    this.apollo
      .watchQuery<Response>({
        query: gql`
    {fin_list(limit:50000,kind: ${kind},module: ${module}
      ,idcompany:${idcompany},pincompany:"${this.pincompany}") {
      fins {
        id
        name
        kind
        module
        typpe
        parent
        image
        note
        nvalue
        fvalue
        svalue
      }
    }}
    `, fetchPolicy: 'no-cache'
      })
      .valueChanges.subscribe(result => {

        action.do(0, result);

      });


  }
  public deletePayment(idn: number, action: Action) {
    this.apollo.mutate({
      mutation: gql`
    mutation( $id:Int!,$idcompany:Int,$pincompany:String
      ) {
      DeletePayment(id: $id,idcompany: $idcompany,pincompany: $pincompany
        )
      {
        error
        message
      }
    }
    `,
      variables: {
        id: idn,
        idcompany: this.idcompany,
        pincompany: this.pincompany
      }
    }).subscribe(({ data }) => {

      action.do(data['DeletePayment'].error);
    }, (error) => {
      console.log('there was an error sending the query', error);
    });

  }
  public deleteAccount(idn: number, action: Action) {
    this.apollo.mutate({
      mutation: gql`
    mutation( $id:Int!,$idcompany:Int,$pincompany:String
      ) {
      DeleteAccount(id: $id,idcompany: $idcompany,pincompany: $pincompany
        )
      {
        error
        message
      }
    }
    `,
      variables: {
        id: idn,
        idcompany: this.idcompany,
        pincompany: this.pincompany

      }
    }).subscribe(({ data }) => {

      action.do(data['DeleteAccount'].error);
    }, (error) => {

      alert('Vous ne pouvez pas supprimer ce compte, peut être il est utilisé dans des paiements ou des transactions');
      console.log('there was an error sending the query', error);
    });

  }
  public deleteTransaction(idn: number, action: Action) {
    this.apollo.mutate({
      mutation: gql`
    mutation( $id:Int!,$idcompany:Int,$pincompany:String) {
      DeleteTransaction(id: $id,idcompany: $idcompany,pincompany: $pincompany
        )
      {
        error
        message
      }
    }
    `,
      variables: {
        id: idn,
        idcompany: this.idcompany,
        pincompany: this.pincompany

      }
    }).subscribe(({ data }) => {

      action.do(data['DeleteTransaction'].error);
    }, (error) => {
      console.log('there was an error sending the query', error);
    });

  }
  public deleteExpense(idn: number, action: Action) {
    this.apollo.mutate({
      mutation: gql`
    mutation( $id:Int!,$idcompany:Int,$pincompany:String) {
      DeleteExpense(id: $id,idcompany:$idcompany,pincompany:$pincompany)
      {
        error
        message
      }
    }
    `,
      variables: {
        id: idn,
        idcompany: this.idcompany,
        pincompany: this.pincompany

      }
    }).subscribe(({ data }) => {

      action.do(data['DeleteExpense'].error);
    }, (error) => {
      console.log('there was an error sending the query', error);
    });

  }

  public deleteSource(idn: number, action: Action) {
    this.apollo.mutate({
      mutation: gql`
    mutation( $id:Int!) {
      DeleteSource(id: $id)
      {
        error
        message
      }
    }
    `,
      variables: {
        id: idn
      }
    }).subscribe(({ data }) => {

      action.do(data['DeleteSource'].error);
    }, (error) => {
      console.log('there was an error sending the query', error);
    });

  }
  public introduceSource(name: string, action: Action) {
    this.apollo
      .mutate<Response>({
        mutation: parse(`
    mutation {
      IntroduceSource(name: ${this.stringred(name)}) {
        id
        name
      }
    }
    `),
      }).subscribe(({ data }) => {
        if (data.IntroduceSource != null) {

          action.do(0, data.IntroduceSource);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public updateSource(id: number, name: string, action: Action) {
    this.apollo
      .mutate<Response>({
        mutation: parse(`
    mutation {
      UpdateSource(id: ${id},name: ${this.stringred(name)}) {
        id
        name
      }
    }
    `),
      }).subscribe(({ data }) => {
        if (data.UpdateSource != null) {
          // this.user = data.IntroduceProduct;
          action.do(0, data.UpdateSource);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public getSources(action: Action) {
    this.apollo
      .watchQuery<Response>({
        query: gql`
    {source_list(limit:50000) {
      sources {
        id
        name
      }
    }}
    `, fetchPolicy: 'no-cache'
      })
      .valueChanges.subscribe(result => {

        action.do(0, result);

      });


  }
  public deleteActivite(idn: number, action: Action) {
    this.apollo.mutate({
      mutation: gql`
    mutation( $id:Int!) {
      DeleteActivite(id: $id)
      {
        error
        message
      }
    }
    `,
      variables: {
        id: idn
      }
    }).subscribe(({ data }) => {

      action.do(data['DeleteActivite'].error);
    }, (error) => {
      console.log('there was an error sending the query', error);
    });

  }
  public introduceActivite(name: string, action: Action) {
    this.apollo
      .mutate<Response>({
        mutation: parse(`
    mutation {
      IntroduceActivite(name: ${this.stringred(name)}) {
        id
        name
      }
    }
    `),
      }).subscribe(({ data }) => {
        if (data.IntroduceActivite != null) {

          action.do(0, data.IntroduceActivite);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public updateActivite(id: number, name: string, action: Action) {
    this.apollo
      .mutate<Response>({
        mutation: parse(`
    mutation {
      UpdateActivite(id: ${id},name: ${this.stringred(name)}) {
        id
        name
      }
    }
    `),
      }).subscribe(({ data }) => {
        if (data.UpdateActivite != null) {
          // this.user = data.IntroduceProduct;
          action.do(0, data.UpdateActivite);
        } else { action.do(1); }

      }, (error) => {
        console.log('there was an error sending the query', error);
      });

  }
  public getActivites(action: Action) {
    this.apollo
      .watchQuery<Response>({
        query: gql`
    {activite_list(limit:50000) {
      activites {
        id
        name
      }
    }}
    `, fetchPolicy: 'no-cache'
      })
      .valueChanges.subscribe(result => {

        action.do(0, result);

      });


  }
  typepayment = [
    { id: 1, txt: 'En espèce', txtr: 'Esp' },
    { id: 2, txt: 'Par chèque', txtr: 'Chq' },
    { id: 3, txt: 'Par virement bancaire', txtr: 'Vir' },
    { id: 4, txt: 'Par CB', txtr: 'CV' },
    { id: 5, txt: 'Merchandise', txtr: 'Mch' },
  ];
  getTypePayment() {
    return this.typepayment;
  }
  filterminus(article) {
    return article.replace("/-/g", "‑")
  }
  getAllRights() {
    return [
      { "id": 1, "code": "ََAFF001", "category": "Affaires", "itemName": "Gestion des Affaires", route: "excel" },
      { "id": 2, "code": "INT001", "category": "Interactions", "itemName": "Gestion des Interactions", route: "alert" },
      { "id": 3, "code": "PRD001", "category": "Produits", "itemName": "Mes Produits", route: "alert" },
      { "id": 4, "code": "PRD002", "category": "Produits", "itemName": "Catégoties des Produits", route: "category" },
      { "id": 5, "code": "PRD003", "category": "Produits", "itemName": "Packs", route: "pack" },
      { "id": 6, "code": "CLT001", "category": "Clients", "itemName": "Listes des Clients", route: "client" },
      { "id": 7, "code": "CLT002", "category": "Clients", "itemName": "Devis", route: "devisn" },
      { "id": 8, "code": "CLT003", "category": "Clients", "itemName": "Facture proforma", route: "proformaclient" },
      { "id": 9, "code": "CLT004", "category": "Clients", "itemName": "Bon de livraison", route: "bonclient" },
      { "id": 10, "code": "CLT005", "category": "Clients", "itemName": "Facture", route: "facturen" },
      { "id": 11, "code": "CLT006", "category": "Clients", "itemName": "Paiements", route: "paymentclient" },
      { "id": 12, "code": "FRN001", "category": "Fournisseurs", "itemName": "Listes des fournisseurs", route: "source" },
      { "id": 13, "code": "FRN002", "category": "Fournisseurs", "itemName": "Bon de commande", route: "fincom" },
      { "id": 14, "code": "FRN003", "category": "Fournisseurs", "itemName": "Bon de réception", route: "fin" },
      { "id": 15, "code": "FRN004", "category": "Fournisseurs", "itemName": "Paiements", route: "payment" },
      { "id": 16, "code": "STK001", "category": "Stock", "itemName": "Listes des magasins", route: "warehouse" },
      { "id": 17, "code": "STK002", "category": "Stock", "itemName": "Etat de stock", route: "etat" },
      { "id": 18, "code": "STK003", "category": "Stock", "itemName": "Transfert inter-magazins", route: "transfer" },
      { "id": 19, "code": "TRS001", "category": "Trésorie", "itemName": "Overview", route: "tresorie" },
      { "id": 20, "code": "TRS002", "category": "Trésorie", "itemName": "Gestion de trésorie", route: "tresoriecou" },
      { "id": 21, "code": "TRS003", "category": "Trésorie", "itemName": "Paramètres des comptes", route: "account" },
      { "id": 22, "code": "TRS004", "category": "Trésorie", "itemName": "Catégorie des charges", route: "accountcategorie" },
      { "id": 23, "code": "TRS005", "category": "Trésorie", "itemName": "Type de compte", route: "accounttype" },
      { "id": 24, "code": "APK001", "category": "Mobile app", "itemName": "Mobile app", route: "bonclientapp" },
      { "id": 25, "code": "ADM001", "category": "Administration", "itemName": "Société", route: "societe" },
      { "id": 26, "code": "ADM002", "category": "Administration", "itemName": "Utilisateurs", route: "societe" },
      { "id": 27, "code": "STT001", "category": "Statistiques", "itemName": "MARGES ET Chiffres d'affaires", route: "custom" },

    ];
  }
  getCollabRights() {
    return [
      { "id": 1, "code": "ََAFF001", "category": "Affaires", "itemName": "Gestion des Affaires", route: "excel" },
      { "id": 2, "code": "INT001", "category": "Interactions", "itemName": "Gestion des Interactions", route: "alert" },
      { "id": 7, "code": "CLT002", "category": "Clients", "itemName": "Devis", route: "devisn" },
      { "id": 8, "code": "CLT003", "category": "Clients", "itemName": "Facture proforma", route: "proformaclient" },
      { "id": 9, "code": "CLT004", "category": "Clients", "itemName": "Bon de livraison", route: "bonclient" },
      { "id": 10, "code": "CLT005", "category": "Clients", "itemName": "Facture", route: "facturen" },
      { "id": 24, "code": "APK001", "category": "Mobile app", "itemName": "Mobile app", route: "bonclientapp" },

    ]
  }
  getAdminRights() {
    return [
      { "id": 1, "code": "ََAFF001", "category": "Affaires", "itemName": "Gestion des Affaires", route: "excel" },
      { "id": 2, "code": "INT001", "category": "Interactions", "itemName": "Gestion des Interactions", route: "alert" },
      { "id": 3, "code": "PRD001", "category": "Produits", "itemName": "Mes Produits", route: "alert" },
      { "id": 4, "code": "PRD002", "category": "Produits", "itemName": "Catégoties des Produits", route: "category" },
      { "id": 5, "code": "PRD003", "category": "Produits", "itemName": "Packs", route: "pack" },
      { "id": 6, "code": "CLT001", "category": "Clients", "itemName": "Listes des Clients", route: "client" },
      { "id": 7, "code": "CLT002", "category": "Clients", "itemName": "Devis", route: "devisn" },
      { "id": 8, "code": "CLT003", "category": "Clients", "itemName": "Facture proforma", route: "proformaclient" },
      { "id": 9, "code": "CLT004", "category": "Clients", "itemName": "Bon de livraison", route: "bonclient" },
      { "id": 10, "code": "CLT005", "category": "Clients", "itemName": "Facture", route: "facturen" },
      { "id": 11, "code": "CLT006", "category": "Clients", "itemName": "Paiements", route: "paymentclient" },
      { "id": 12, "code": "FRN001", "category": "Fournisseurs", "itemName": "Listes des fournisseurs", route: "source" },
      { "id": 13, "code": "FRN002", "category": "Fournisseurs", "itemName": "Bon de commande", route: "fincom" },
      { "id": 14, "code": "FRN003", "category": "Fournisseurs", "itemName": "Bon de réception", route: "fin" },
      { "id": 15, "code": "FRN004", "category": "Fournisseurs", "itemName": "Paiements", route: "payment" },
      { "id": 17, "code": "STK002", "category": "Stock", "itemName": "Etat de stock", route: "etat" },
      { "id": 18, "code": "STK003", "category": "Stock", "itemName": "Transfert inter-magazins", route: "transfer" },
      { "id": 19, "code": "TRS001", "category": "Trésorie", "itemName": "Overview", route: "tresorie" },
      { "id": 20, "code": "TRS002", "category": "Trésorie", "itemName": "Gestion de trésorie", route: "tresoriecou" },
      { "id": 24, "code": "APK001", "category": "Mobile app", "itemName": "Mobile app", route: "bonclientapp" },
      { "id": 26, "code": "ADM002", "category": "Administration", "itemName": "Utilisateurs", route: "societe" },
      { "id": 27, "code": "STT001", "category": "Statistiques", "itemName": "MARGES ET Chiffres d'affaires", route: "custom" },

    ]
  }
  getVisitRights() {
    return [
      { "id": 1, "code": "ََAFF001", "category": "Affaires", "itemName": "Gestion des Affaires", route: "excel" },
      { "id": 2, "code": "INT001", "category": "Interactions", "itemName": "Gestion des Interactions", route: "alert" },
      { "id": 6, "code": "CLT001", "category": "Clients", "itemName": "Listes des Clients", route: "client" },
    ]
  }

  hideLinks() {
    if (!this.user) return;
    var allrights = this.getAllRights();
    var rights = this.user.rights.split('$');
    if (rights) {
      allrights.forEach(r => {
        if (!rights.includes("" + r.id))
          $('#' + r.code).remove();
      })
    }
    $('.childed:not(:has(li))').hide();
  }
}
